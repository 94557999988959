import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import {Auth} from 'aws-amplify';
import {NavLink} from 'react-router-dom';
import HeliosTextLogo from '../../images/HELIOS_Wordmark_White_20220601.png';
import {useWindowSize} from '../../hooks/useWindowSize';
import {useAppSelector} from '../../store/hooks';
import {Roles} from '../../enums';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import {styled, useTheme} from '@mui/material/styles';
import TeamsSideMenu from './components/TeamsSideMenu';

const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})<{
  open?: boolean;
}>(({theme, open}) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface StyledAppBarProps extends AppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<StyledAppBarProps>(({theme, open}) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const ProfileInfo = styled(Box)(() => ({
  position: 'relative',
  top: '-30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ProfileAvatar = styled(Avatar)(() => ({
  width: '100px',
  height: '100px',
  marginBottom: '10px',
  background: '#000',
  border: 'solid 1px #fff',
}));

interface Props {
  children: ReactNode;
}

const BaseLayout: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {children} = props;

  const currentState = useAppSelector(state => ({
    profile: state.profile,
    team: state.team,
  }));

  const profile = currentState.profile?.profile;
  const teams = currentState.team?.teams ?? [];

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [width] = useWindowSize();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <StyledAppBar position="fixed" open={open || width > 1200}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{mr: 2, ...((open || width > 1200) && {display: 'none'})}}>
            <MenuIcon />
          </IconButton>
          <Box sx={{flexGrow: 1}}>
            <img src={HeliosTextLogo} alt="Helios Hockey" height={25} />
          </Box>
          <Button
            color="inherit"
            onClick={async () => {
              await Auth.signOut();
              window.location.href = '/';
            }}>
            Logout
          </Button>
        </Toolbar>
      </StyledAppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open || width > 1200}>
        <DrawerHeader>
          {width < 1200 && (
            <IconButton sx={{zIndex: 2}} onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <ProfileInfo>
          {profile?.profilePictureFileKey ? (
            <ProfileAvatar
              alt={`${profile?.firstName} ${profile?.lastName}`}
              src={profile?.profilePictureFileKey}
            />
          ) : (
            <ProfileAvatar alt={`${profile?.firstName} ${profile?.lastName}`}>
              <PersonIcon sx={{fontSize: '80px'}} color="info" />
            </ProfileAvatar>
          )}
          <Typography variant="h4">
            <em>
              {`${profile?.firstName} ${profile?.lastName}`.toUpperCase()}
            </em>
          </Typography>
          <Typography variant="h5" color="primary">
            {(profile?.role ?? '').toUpperCase()}
          </Typography>
        </ProfileInfo>
        <List>
          {!(profile?.role === 'coach' && (teams ?? []).length > 0) && (
            <ListItemButton component={NavLink} to="/">
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{fontWeight: 700, fontSize: '1.2rem'}}
              />
            </ListItemButton>
          )}
          {profile?.role === Roles.admin && (
            <ListItemButton component={NavLink} to="/admin">
              <ListItemText
                primary="Admin"
                primaryTypographyProps={{fontWeight: 700, fontSize: '1.2rem'}}
              />
            </ListItemButton>
          )}
          {(profile?.role === Roles.player || profile?.role === Roles.pro) && (
            <ListItemButton component={NavLink} to="/membership">
              <ListItemText
                primary="Membership"
                primaryTypographyProps={{fontWeight: 700, fontSize: '1.2rem'}}
              />
            </ListItemButton>
          )}
          {(profile?.role === Roles.coach || profile?.role === Roles.admin) && (
            <TeamsSideMenu teams={teams} />
          )}
          <ListItemButton component={NavLink} to="/settings">
            <ListItemText
              primary="Settings"
              primaryTypographyProps={{fontWeight: 700, fontSize: '1.2rem'}}
            />
          </ListItemButton>
        </List>
      </Drawer>
      <Main
        open={open || width > 1200}
        sx={{
          maxWidth: '100%',
          p: {
            xs: 1,
            md: 3,
          },
        }}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};

export default BaseLayout;
