import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  styled,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getPlayerSessionDetailAsync} from '../../store/playerSessionSlice';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {Helmet} from 'react-helmet';
import {CurrentMembershipLevels, Roles, SessionTypes} from '../../enums';
import SessionVideoManager from '../shared/SessionVideoManager';
import {DateTime} from 'luxon';
import MembershipLocked from '../shared/MembershipLocked';
import PageTitle from '../shared/PageTitle';
import PageSubtitle from '../shared/PageSubtitle';
import {useTranslation} from 'react-i18next';
import BackButton from '../shared/BackButton';
import {getTime} from '../../services/dateService';
import {getIntensityText} from '../../services/playerSessionService';
import {
  getSpeedMultiplier,
  getSpeedUnit,
} from '../../services/measurementService';
import PlayerSessionShifts from '../shared/PlayerSessionShifts';
import {VictoryAxis} from 'victory';
import PlayerSessionLineChart from '../shared/PlayerSessionLineChart';
import {yAxisStyle} from '../../services/graphService';
import {SizeMe} from 'react-sizeme';
import ProfileIdentity from '../shared/ProfileIdentity';
import ExportSessionModal from '../shared/ExportSessionModal';
import SubtitleContainer from '../shared/SubtitleContainer';
import {PlayerSessionShift} from '../../interfaces';
import SessionGridItem from '../shared/SessionGridItem';
import SessionVideoPlayer from '../shared/SessionVideoPlayer';
import PlayerNumber from '../shared/PlayerNumber';
import DownloadSessionJson from '../Admin/DownloadSessionJson';

const ShiftTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledSessionType = styled('span')(() => ({
  display: 'block',
  textTransform: 'capitalize',
}));

interface Props {}

const PlayerSessionDetail: React.FC<Props> = () => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    team: state.team,
    playerSession: state.playerSession,
    family: state.family,
  }));
  const role = useAppSelector(state => state.profile?.profile?.role);
  const [showShiftReview, setShowShiftReview] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {teamId, profileId, teamSessionId, playerSessionId} = useParams();
  const playerSessionIdInt = parseInt(playerSessionId ?? '');
  const teamIdInt = parseInt(teamId ?? '');
  const profileIdInt = parseInt(profileId ?? '');
  const location = useLocation();
  const hash = location.hash;
  const teamSessionGroupId =
    currentState?.playerSession?.playerSession?.teamSession?.teamSessionGroupId;

  let backPath = '/';
  let backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  if (currentState?.profile?.profile?.role === Roles.parent) {
    backPath = `/family/children/${currentState?.playerSession?.playerSession?.player?.profile?.id}/playersessions`;
    backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  } else if (teamId && profileId && teamSessionGroupId) {
    backPath = `/playersessions/groups/${teamSessionGroupId}${hash}`;
    backText = t('navigation.back', {
      pageName: t('pages.playerSessionGroupDetail'),
    });
  } else if (teamId && profileId) {
    backPath = `/teams/${teamId}/players/${profileId}/playersessions`;
    backText = t('navigation.back', {pageName: t('pages.playerSessions')});
  } else if (teamId && teamSessionId && teamSessionGroupId) {
    const profileIdForReturn =
      currentState.playerSession?.playerSession?.player?.profileId || 0;
    backPath = `/teams/${teamId}/players/${profileIdForReturn}/playersessions/groups/${teamSessionGroupId}${hash}`;
    backText = t('navigation.back', {
      pageName: t('pages.playerSessionGroupDetail'),
    });
  } else if (teamId && teamSessionId) {
    backPath = `/teams/${teamId}/teamsessions/${teamSessionId}`;
    backText = t('navigation.back', {pageName: t('pages.teamSessionDetail')});
  }

  useEffect(() => {
    dispatch(getPlayerSessionDetailAsync(playerSessionIdInt));
  }, [dispatch, playerSessionIdInt]);

  const title = t('navigation.pageTitle', {
    pageName: t('pages.playerSessionDetail'),
  });

  const playerSession = currentState.playerSession?.playerSession;

  const shiftClips: PlayerSessionShift[] = (
    currentState.playerSession?.playerSession?.playerSessionShifts ?? []
  ).filter(
    (shift: PlayerSessionShift) =>
      shift.valid && (shift.playerSessionVideoClips ?? []).length > 0,
  );

  const timeData = [].concat(
    playerSession?.speedAvgData ?? [],
    playerSession?.accelAvgData ?? [],
    playerSession?.strideRateAvgData ?? [],
    playerSession?.balanceAvgData ?? [],
    playerSession?.heartRateData ?? [],
  );

  const maxTime =
    timeData.length > 0
      ? timeData
          .map((point: {time: number}) => point.time)
          .sort(function (a: number, b: number) {
            return a - b;
          })[timeData.length - 1]
      : 0;

  const shifts =
    (playerSession?.playerSessionShifts ?? []).length > 0
      ? playerSession?.playerSessionShifts.filter(
          (x: PlayerSessionShift) =>
            x.valid || (showShiftReview && x.canUpdateValid),
        )
      : (playerSession?.clips ?? []).filter((x: PlayerSessionShift) => x.valid);

  const speedMultiplier = getSpeedMultiplier(
    currentState.profile?.profile?.preferredUnits === 'metric',
  );
  const speedUnit = getSpeedUnit(
    currentState.profile?.profile?.preferredUnits === 'metric',
  );

  const canSeeShifts =
    playerSession?.membershipLevel === CurrentMembershipLevels.elite ||
    playerSession?.membershipLevel === CurrentMembershipLevels.pro ||
    [Roles.admin, Roles.coach, Roles.trainer].indexOf(
      currentState.profile?.profile?.role,
    ) !== -1;

  if (
    currentState.playerSession.status === 'loading' ||
    !currentState.playerSession?.playerSession
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>Player Session</PageTitle>
        <BackButton navigationUrl={backPath}>{backText}</BackButton>
        {currentState?.profile?.profile?.id !==
          currentState.playerSession?.playerSession?.player?.profile?.id && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <PlayerNumber
              number={currentState.playerSession?.playerSession?.player?.number}
            />
            <ProfileIdentity
              firstName={
                currentState.playerSession?.playerSession?.player?.profile
                  ?.firstName
              }
              lastName={
                currentState.playerSession?.playerSession?.player?.profile
                  ?.lastName
              }
              position={
                currentState.playerSession?.playerSession?.player?.profile
                  ?.position
              }
              profilePictureUrl={
                currentState.playerSession?.playerSession?.player?.profile
                  ?.profilePictureFileKey
              }
            />
          </Box>
        )}
        {currentState.playerSession?.playerSession?.startTime && (
          <SubtitleContainer>
            <PageSubtitle sx={{flexGrow: 1, mr: 2}}>
              <StyledSessionType>
                {(playerSession.type ?? '').toUpperCase()}
              </StyledSessionType>
              {DateTime.fromISO(
                currentState.playerSession?.playerSession?.startTime,
              ).toFormat('EEEE M/d/yyyy h:mm a')}
            </PageSubtitle>
            <ExportSessionModal
              buttonSx={{mb: 2, alignSelf: 'center'}}
              playerSessionId={playerSessionIdInt}
              playerSessionStartTime={playerSession.startTime}
            />
          </SubtitleContainer>
        )}
        {role === Roles.admin && (
          <Box sx={{display: 'flex', flexDirection: 'row', marginX: -2}}>
            <DownloadSessionJson
              fileTitle={`playersession_${playerSession.id}_${DateTime.fromISO(
                playerSession.startTime,
              ).toFormat('yyyyMMddhhmmss')}`}
              playerSessionIds={[playerSession.id]}
            />
          </Box>
        )}
        <PageSubtitle>{t('video.instantVideoTitle')}</PageSubtitle>
        {currentState?.playerSession?.playerSession?.membershipLevel ===
        CurrentMembershipLevels.pro ? (
          <>
            {currentState?.playerSession?.playerSession?.clipsUrl && (
              <Button
                sx={{mb: 2}}
                variant="contained"
                href={currentState?.playerSession?.playerSession?.clipsUrl}
                download>
                {t('video.downloadAllClips')}
              </Button>
            )}
            {/* if user is player or parent then they can manage session videos */}
            {!currentState?.playerSession?.playerSession?.teamSessionId &&
            (currentState?.profile?.profile?.role === Roles.player ||
              currentState?.profile?.profile?.role === Roles.pro ||
              currentState?.profile?.profile?.role === Roles.parent ||
              currentState?.profile?.profile?.role === Roles.coach ||
              currentState?.profile?.profile?.role === Roles.admin) ? (
              <SessionVideoManager
                sessionVideos={
                  currentState.playerSession?.playerSession?.sessionVideos
                }
                sessionType="player"
                teamId={teamIdInt}
                profileId={profileIdInt}
                sessionId={playerSessionIdInt}
              />
            ) : (
              <Alert severity="info" variant="outlined" sx={{mb: 2}}>
                {t('video.unableToManageTeamSessionClipsAsPlayer')}
              </Alert>
            )}
            {shiftClips.length > 0 && (
              <SessionVideoPlayer shiftClips={shiftClips} />
            )}
          </>
        ) : (
          <MembershipLocked minMembershipLevel={CurrentMembershipLevels.pro} />
        )}
        {/* game shifts */}
        {playerSession.type === SessionTypes.game && (
          <>
            {canSeeShifts ? (
              <>
                <ShiftTitleWrapper>
                  <PageSubtitle>{t('shifts.title')}</PageSubtitle>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => setShowShiftReview(!showShiftReview)}>
                      {t('shifts.shiftReview')}
                    </Button>
                  </Box>
                </ShiftTitleWrapper>
                <PlayerSessionShifts
                  playerSessionIds={[playerSessionIdInt]}
                  shifts={shifts}
                  inReview={showShiftReview}
                  setInReview={setShowShiftReview}
                  isProcessingVideo={!!playerSession?.videoProcessingStartedAt}
                  sessionVideoIds={
                    playerSession?.sessionVideos?.length > 0 &&
                    playerSession?.sessionVideos[0].sessionVideoSegments
                      ?.length > 0
                      ? [playerSession?.sessionVideos[0].id]
                      : []
                  }
                />
              </>
            ) : (
              <>
                <PageSubtitle>{t('shifts.title')}</PageSubtitle>
                <MembershipLocked
                  minMembershipLevel={CurrentMembershipLevels.elite}
                />
              </>
            )}
          </>
        )}
        {/* standard session stats */}
        <Paper sx={{mt: 5, mb: 5}}>
          <Grid container spacing={2}>
            <SessionGridItem
              label="Strides"
              value={
                playerSession.strideCountLeft + playerSession.strideCountRight
              }
            />
            <SessionGridItem
              label="Active Time"
              value={getTime(playerSession.activeTime) ?? '--'}
            />
            <SessionGridItem
              label="Stride Time"
              value={getTime(playerSession.strintTotalTime) ?? '--'}
            />
            <SessionGridItem
              label="Agility (Avg)"
              value={
                playerSession.strintFilteredStrideRateAvg
                  ? playerSession.strintFilteredStrideRateAvg.toFixed(0)
                  : '--'
              }
              unit={'spm'}
            />
            <SessionGridItem
              label="Agility (Max)"
              value={
                playerSession.strintFilteredStrideRateMax
                  ? playerSession.strintFilteredStrideRateMax.toFixed(0)
                  : '--'
              }
              unit={'spm'}
            />
            <SessionGridItem
              label="Points"
              value={playerSession.points ?? '--'}
            />
            <SessionGridItem
              label="Hustle"
              value={
                playerSession.hustleScore
                  ? getIntensityText(playerSession.hustleScore).toUpperCase()
                  : '--'
              }
            />
            <SessionGridItem
              label="Stride Speed (Avg)"
              value={
                playerSession.speedAvg
                  ? (playerSession.speedAvg * speedMultiplier).toFixed(1)
                  : '--'
              }
              unit={speedUnit}
            />
            <SessionGridItem
              label="Stride Speed (Max)"
              value={
                playerSession.speedMax
                  ? (playerSession.speedMax * speedMultiplier).toFixed(1)
                  : '--'
              }
              unit={speedUnit}
            />
            <SessionGridItem
              label="Explosiveness (Avg)"
              value={
                playerSession.accelAvg
                  ? (playerSession.accelAvg / 9.8).toFixed(2)
                  : '--'
              }
              unit="g"
            />
            <SessionGridItem
              label="Explosiveness (Max)"
              value={
                playerSession.accelMax
                  ? (playerSession.accelMax / 9.8).toFixed(2)
                  : '--'
              }
              unit="g"
            />
            <SessionGridItem
              label="Balance (%L / %R)"
              value={
                playerSession.balance
                  ? `${Math.round(playerSession.balance * 100)} / ${Math.round((1 - playerSession.balance) * 100)}`
                  : '--'
              }
            />
            {/* TODO, this item should show lock if user is not elite or pro */}
            <SessionGridItem
              label="Heart Rate (Avg)"
              value={playerSession.hrAvg ?? '--'}
              unit="bpm"
            />
          </Grid>
          {playerSession?.speedAvgData?.length > 0 && (
            <Box sx={{m: 5}}>
              <Typography variant="h4">
                {t('statistics.strideSpeedDetail')}
              </Typography>
              <PlayerSessionLineChart
                data={playerSession?.speedAvgData.map((point: any) => ({
                  x: point.time,
                  y: point.value * speedMultiplier,
                }))}
                metric={` ${speedUnit}`}
                maxTime={maxTime}
              />
            </Box>
          )}
          {playerSession?.accelAvgData?.length > 0 && (
            <Box sx={{m: 5}}>
              <Typography variant="h4">
                {t('statistics.explosivenessDetail')}
              </Typography>
              <PlayerSessionLineChart
                data={playerSession?.accelAvgData.map((point: any) => ({
                  x: point.time,
                  y: point.value / 9.8,
                }))}
                metric={' g'}
                maxTime={maxTime}
              />
            </Box>
          )}
          {playerSession?.strideRateAvgData?.length > 0 && (
            <Box sx={{m: 5}}>
              <Typography variant="h4">
                {t('statistics.strideRateDetail')}
              </Typography>
              <PlayerSessionLineChart
                data={playerSession?.strideRateAvgData.map((point: any) => ({
                  x: point.time,
                  y: point.value,
                }))}
                metric={' spm'}
                maxTime={maxTime}
              />
            </Box>
          )}
          {playerSession?.balanceAvgData?.length > 0 && (
            <Box sx={{m: 5}}>
              <Typography variant="h4">
                {t('statistics.balanceDetail')}
              </Typography>
              <Box>
                <SizeMe>
                  {({size}) => (
                    <PlayerSessionLineChart
                      data={playerSession?.balanceAvgData.map((point: any) => ({
                        x: point.time,
                        y: point.value,
                      }))}
                      yAxisOverride={
                        <VictoryAxis
                          dependentAxis
                          tickValues={[0.05, 0.5, 0.95]}
                          tickFormat={tick => {
                            if (tick === 0.05) {
                              return 'Right';
                            } else if (tick === 0.5) {
                              return 'Balanced';
                            } else if (tick === 0.95) {
                              return 'Left';
                            }

                            return '';
                          }}
                          orientation="right"
                          style={yAxisStyle}
                          offsetX={size.width ?? 0}
                        />
                      }
                      metric={''}
                      maxTime={maxTime}
                    />
                  )}
                </SizeMe>
              </Box>
            </Box>
          )}
          {playerSession?.heartRateData?.length > 0 && (
            <Box sx={{m: 5}}>
              <Typography variant="h4">
                {t('statistics.hrAvgDetail')}
              </Typography>
              {playerSession?.membershipLevel ===
                CurrentMembershipLevels.elite ||
              playerSession?.membershipLevel === CurrentMembershipLevels.pro ? (
                <PlayerSessionLineChart
                  data={playerSession?.heartRateData.map((point: any) => ({
                    x: point.time,
                    y: point.value1,
                  }))}
                  metric={' bpm'}
                  maxTime={maxTime}
                />
              ) : (
                <MembershipLocked
                  minMembershipLevel={CurrentMembershipLevels.elite}
                />
              )}
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default PlayerSessionDetail;
