import {DateTime} from 'luxon';
import {
  AllTimeComparisonSummary,
  Baseline,
  PlayerSession,
  SummaryInRange,
} from '../interfaces';
import {getSpeedMultiplier} from './measurementService';
import {SessionTypes} from '../enums';

const ALLTIME_COMPARISON_COUNT = 50;

const hrMinReducer = (acc: number, value: any) =>
  (acc =
    (acc === undefined || acc === 0 || value.hrMin < acc) && value.hrMin
      ? value.hrMin
      : acc);

const hrMaxReducer = (acc: number, value: any) =>
  (acc = acc === undefined || value.hrMax > acc ? value.hrMax : acc);

const hrZoneTimeReducer = (acc: number[], value: any) => {
  acc[0] = acc[0] + value.hrZone1Time;
  acc[1] = acc[1] + value.hrZone2Time;
  acc[2] = acc[2] + value.hrZone3Time;
  acc[3] = acc[3] + value.hrZone4Time;
  acc[4] = acc[4] + value.hrZone5Time;
  return acc;
};

const sessionFilter = (
  session: any,
  startDaysAgo: number,
  endDaysAgo: number,
  endOfCurrentDay: DateTime,
) => {
  const diff = DateTime.fromISO(session.startTime).diff(
    endOfCurrentDay,
    'days',
  ).days;

  return (
    diff > startDaysAgo * -1 && diff < endDaysAgo * -1 && session.activeTime > 0
  );
};

const getTotalStridesInRange = (
  playerSessions: any,
  startDaysAgo: number,
  endDaysAgo: number,
) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  return playerSessions
    .filter((session: any) =>
      sessionFilter(session, startDaysAgo, endDaysAgo, endOfCurrentDay),
    )
    .reduce(
      (acc: number, value: any) =>
        acc + value.strideCountLeft + value.strideCountRight,
      0,
    );
};

const getTimeOnIceInRange = (
  playerSessions: any,
  startDaysAgo: number,
  endDaysAgo: number,
) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  return playerSessions
    .filter((session: any) =>
      sessionFilter(session, startDaysAgo, endDaysAgo, endOfCurrentDay),
    )
    .reduce((acc: number, value: any) => acc + value.activeTime, 0);
};

const getHrDataInRange = (
  playerSessions: any,
  startDaysAgo: number,
  endDaysAgo: number,
) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  const sessions = playerSessions.filter((session: any) =>
    sessionFilter(session, startDaysAgo, endDaysAgo, endOfCurrentDay),
  );

  return {
    hrMin: sessions.reduce(hrMinReducer, 0),
    hrMax: sessions.reduce(hrMaxReducer, 0),
    hrAvg:
      sessions.filter((session: any) => session.hrAvg > 0).length > 0
        ? sessions.reduce((acc: number, value: any) => acc + value.hrAvg, 0) /
          sessions.filter((session: any) => session.hrAvg > 0).length
        : 0,
    hrZoneTime: sessions.reduce(hrZoneTimeReducer, [0, 0, 0, 0, 0]),
  };
};

const getActiveDaysInRange = (
  playerSessions: any,
  startDaysAgo: number,
  endDaysAgo: number,
) => {
  const endOfCurrentDay = DateTime.local().endOf('day');
  const sessionDaysAgo = playerSessions
    .filter((session: any) =>
      sessionFilter(session, startDaysAgo, endDaysAgo, endOfCurrentDay),
    )
    .map((session: any) =>
      Math.floor(
        DateTime.fromISO(session.startTime).diff(endOfCurrentDay, 'days').days,
      ),
    );

  return [...new Set(sessionDaysAgo)].length;
};

const transformPlayerSession = (
  playerSession: PlayerSession,
  isMetric: boolean = false,
) => {
  const speedMultiplier = getSpeedMultiplier(isMetric);
  const transformedPlayerSession = {...playerSession};
  transformedPlayerSession.speedAvg =
    (transformedPlayerSession.speedAvg || 0) * speedMultiplier;
  transformedPlayerSession.speedMax =
    (transformedPlayerSession.speedMax || 0) * speedMultiplier;
  transformedPlayerSession.accelAvg =
    (transformedPlayerSession.accelAvg || 0) / 9.8;
  transformedPlayerSession.accelMax =
    (transformedPlayerSession.accelMax || 0) / 9.8;
  return transformedPlayerSession;
};

const getDayAverage = (day: any, statName: string) =>
  day.filter((session: any) => session[statName]).length > 0
    ? day.reduce((acc: number, value: any) => acc + value[statName], 0) /
      day.filter((session: any) => session[statName]).length
    : 0;

const getDayMax = (day: any, statName: string) => {
  const filteredArray: number[] = day.map(
    (session: any) => session[statName] ?? 0,
  );
  return filteredArray.length ? Math.max(...filteredArray) : 0;
};

const getAvergageInRange = (avgByDay: any[]) =>
  avgByDay.filter(day => day > 0).length > 0
    ? avgByDay.reduce((acc: number, value: number) => acc + value) /
      avgByDay.filter(day => day > 0).length
    : 0;

const getMaxInRange = (maxOfDay: number[]) => {
  return Math.max(...maxOfDay);
};

const getSummaryInRange = ({
  playerSessions,
  baselines,
  startDaysAgo,
  endDaysAgo,
  isMetric = false,
}: {
  playerSessions: PlayerSession[];
  baselines: Baseline[];
  startDaysAgo: number;
  endDaysAgo: number;
  isMetric: boolean;
}): SummaryInRange => {
  const totalStrides = getTotalStridesInRange(
    playerSessions,
    startDaysAgo,
    endDaysAgo,
  );
  const totalTimeOnIce = getTimeOnIceInRange(
    playerSessions,
    startDaysAgo,
    endDaysAgo,
  );
  const hrData = getHrDataInRange(playerSessions, startDaysAgo, endDaysAgo);
  const activeDays = getActiveDaysInRange(
    playerSessions,
    startDaysAgo,
    endDaysAgo,
  );
  const averageTimeOnIce =
    activeDays > 0 ? Math.ceil(totalTimeOnIce / activeDays) : 0;
  const averageStrides =
    activeDays > 0 ? Math.ceil(totalStrides / activeDays) : 0;

  const dateArrayLength = startDaysAgo - endDaysAgo;

  // create the date array
  const dateArray = Array(dateArrayLength);
  const dateArrayPractices = Array(dateArrayLength);
  const dateArrayGames = Array(dateArrayLength);
  const baselineDateArray = Array(dateArrayLength);
  const baselineDateArrayPractices = Array(dateArrayLength);
  const baselineDateArrayGames = Array(dateArrayLength);
  for (let j = 0; j < dateArrayLength; j++) {
    dateArray[j] = [];
    dateArrayPractices[j] = [];
    dateArrayGames[j] = [];
    baselineDateArray[j] = [];
    baselineDateArrayPractices[j] = [];
    baselineDateArrayGames[j] = [];
  }

  // populate the date array
  for (let i = 0; i < playerSessions.length; i++) {
    const currentSessionDate = playerSessions[i].startTime;
    if (!currentSessionDate) {
      continue;
    }
    const daysAgo = Math.floor(
      DateTime.fromISO(currentSessionDate).diff(
        DateTime.local().endOf('day'),
        'days',
      ).days * -1,
    );

    if (daysAgo < startDaysAgo && daysAgo >= endDaysAgo) {
      const transformedSession = transformPlayerSession(
        playerSessions[i],
        isMetric,
      );
      dateArray[daysAgo - endDaysAgo].push(transformedSession);
      if (transformedSession.type === SessionTypes.game) {
        dateArrayGames[daysAgo - endDaysAgo].push(transformedSession);
      }
      if (transformedSession.type === SessionTypes.practice) {
        dateArrayPractices[daysAgo - endDaysAgo].push(transformedSession);
      }
    }
  }
  for (let i = 0; i < (baselines ?? []).length; i++) {
    const currentBaselineDate = baselines[i]?.lastSessionDate;
    if (!currentBaselineDate) {
      continue;
    }
    const daysAgo = Math.floor(
      DateTime.fromISO(currentBaselineDate).diff(
        DateTime.local().endOf('day'),
        'days',
      ).days * -1,
    );

    if (daysAgo < startDaysAgo && daysAgo >= endDaysAgo) {
      const transformedBaseline = transformPlayerSession(
        baselines[i],
        isMetric,
      );
      baselineDateArray[daysAgo - endDaysAgo].push(transformedBaseline);
      if (transformedBaseline.type === SessionTypes.game) {
        baselineDateArrayGames[daysAgo - endDaysAgo].push(transformedBaseline);
      }
      if (transformedBaseline.type === SessionTypes.practice) {
        baselineDateArrayPractices[daysAgo - endDaysAgo].push(
          transformedBaseline,
        );
      }
    }
  }

  const speedAvg = getAvergageInRange(
    dateArray.map(day => getDayAverage(day, 'speedAvg')),
  );

  const speedMax = getMaxInRange(
    dateArray.map(day => getDayMax(day, 'speedMax')),
  );

  const accelAvg = getAvergageInRange(
    dateArray.map(day => getDayAverage(day, 'accelAvg')),
  );

  const accelMax = getMaxInRange(
    dateArray.map(day => getDayMax(day, 'accelMax')),
  );

  const strintFilteredStrideRateAvg = getAvergageInRange(
    dateArray.map(day => getDayAverage(day, 'strintFilteredStrideRateAvg')),
  );

  const strintFilteredStrideRateMax = getMaxInRange(
    dateArray.map(day => getDayMax(day, 'strintFilteredStrideRateMax')),
  );

  const balanceNormalized = getAvergageInRange(
    baselineDateArray.map(day => getDayAverage(day, 'balanceNormalized') * 200),
  );

  return {
    totalStrides,
    totalTimeOnIce,
    activeDays,
    averageStrides,
    averageTimeOnIce,
    speedAvg,
    speedMax,
    accelAvg,
    accelMax,
    strintFilteredStrideRateAvg,
    strintFilteredStrideRateMax,
    balanceNormalized,
    hrMin: hrData.hrMin,
    hrMax: hrData.hrMax,
    hrAvg: hrData.hrAvg,
    hrZoneTime: hrData.hrZoneTime,
    timeAboveTarget: hrData.hrZoneTime[3] + hrData.hrZoneTime[4],
    dateArray,
    dateArrayGames,
    dateArrayPractices,
    baselineDateArray,
    baselineDateArrayGames,
    baselineDateArrayPractices,
  };
};

const getFirstAndLastAllTimeComparisonBaselines = (baselines: Baseline[]) => {
  const sortedSessions = baselines
    .filter(a => !!a.lastSessionDate)
    .sort(
      (a, b) =>
        new Date(a.lastSessionDate!).getTime() -
        new Date(b.lastSessionDate!).getTime(),
    );
  const baselineSessionsByDate: number[] = [
    ...new Set(
      sortedSessions.map(a =>
        DateTime.fromISO(a.lastSessionDate as string)
          .startOf('day')
          .valueOf(),
      ),
    ),
  ];
  if (baselineSessionsByDate.length < 40) {
    return null;
  }
  const firstAllTimeComparisonDates = baselineSessionsByDate.slice(
    0,
    ALLTIME_COMPARISON_COUNT,
  );
  const firstAllTimeComparisonBaselines = firstAllTimeComparisonDates.map(
    dateValue => {
      const matchingSessions = sortedSessions.filter(
        session =>
          DateTime.fromISO(session.lastSessionDate as string)
            .startOf('day')
            .valueOf() === dateValue,
      );
      return {
        date: dateValue,
        sessions: matchingSessions,
      };
    },
  );
  const lastAllTimeComparisonDates = baselineSessionsByDate.slice(
    -ALLTIME_COMPARISON_COUNT,
  );
  const lastAllTimeComparisonBaselines = lastAllTimeComparisonDates.map(
    dateValue => {
      const matchingSessions = sortedSessions.filter(
        session =>
          DateTime.fromISO(session.lastSessionDate as string)
            .startOf('day')
            .valueOf() === dateValue,
      );
      return {
        date: dateValue,
        sessions: matchingSessions,
      };
    },
  );
  return {
    firstAllTimeComparisonBaselines,
    lastAllTimeComparisonBaselines,
  };
};

const getFirstAndLastAllTimeComparisonSessions = (
  playerSessions: PlayerSession[],
) => {
  // Filter sessions with valid startTime and parse dates only once
  const sessionsWithParsedDates = playerSessions
    .filter(session => !!session.startTime)
    .map(session => ({
      ...session,
      parsedStartTime: new Date(session.startTime!).getTime(),
      dayValue: DateTime.fromISO(session.startTime!).startOf('day').valueOf(),
    }));

  if (sessionsWithParsedDates.length < 40) {
    return null; // Early return if not enough sessions
  }

  // Sort by parsed start time
  const sortedSessions = sessionsWithParsedDates.sort(
    (a, b) => a.parsedStartTime - b.parsedStartTime,
  );

  // Group sessions by unique day values
  const sessionsByDay = new Map<number, PlayerSession[]>();
  sortedSessions.forEach(session => {
    const dayValue = session.dayValue;
    if (!sessionsByDay.has(dayValue)) {
      sessionsByDay.set(dayValue, []);
    }
    sessionsByDay.get(dayValue)!.push(session);
  });

  // Get unique days and early return for small input
  const uniqueDays = Array.from(sessionsByDay.keys());
  if (uniqueDays.length < ALLTIME_COMPARISON_COUNT * 2) {
    return null;
  }

  // Extract first and last 20 days
  const firstAllTimeComparison = uniqueDays.slice(0, ALLTIME_COMPARISON_COUNT);
  const lastAllTimeComparison = uniqueDays.slice(-ALLTIME_COMPARISON_COUNT);

  // Map to result format
  const mapDatesToSessions = (dates: number[]) =>
    dates.map(dateValue => ({
      date: dateValue,
      sessions: sessionsByDay.get(dateValue) || [],
    }));

  const firstAllTimeComparisonSessions = mapDatesToSessions(
    firstAllTimeComparison,
  );
  const lastAllTimeComparisonSessions = mapDatesToSessions(
    lastAllTimeComparison,
  );
  return {
    firstAllTimeComparisonSessions,
    lastAllTimeComparisonSessions,
  };
};

const getSummaryInRangeGroupedByDay = (
  sessionGroups: {
    date: number;
    sessions: PlayerSession[];
  }[],
  baselineGroups: {
    date: number;
    sessions: Baseline[];
  }[],
  isMetric = false,
) => {
  const transformedSessionGroups = sessionGroups.map(sg => {
    return {
      date: sg.date,
      sessions: sg.sessions.map(s => transformPlayerSession(s, isMetric)),
    };
  });
  const allSessionsUngrouped = transformedSessionGroups.flatMap(
    sg => sg.sessions,
  );
  const totalStrides = allSessionsUngrouped.reduce(
    (acc: number, curr: PlayerSession) => {
      const strideCount =
        (curr.strideCountLeft || 0) + (curr.strideCountRight || 0);
      return acc + strideCount;
    },
    0,
  );
  const totalTimeOnIce = allSessionsUngrouped.reduce(
    (acc: number, curr: PlayerSession) => {
      return acc + (curr.activeTime || 0);
    },
    0,
  );

  const hrZoneTime = allSessionsUngrouped.reduce(
    hrZoneTimeReducer,
    [0, 0, 0, 0, 0],
  );

  const speedAvg = getAvergageInRange(
    transformedSessionGroups.map(day =>
      getDayAverage(day.sessions, 'speedAvg'),
    ),
  );
  const speedMax = getMaxInRange(
    transformedSessionGroups.map(day => getDayMax(day.sessions, 'speedMax')),
  );
  const accelAvg = getAvergageInRange(
    transformedSessionGroups.map(day =>
      getDayAverage(day.sessions, 'accelAvg'),
    ),
  );
  const accelMax = getMaxInRange(
    transformedSessionGroups.map(day => getDayMax(day.sessions, 'accelMax')),
  );
  const strintFilteredStrideRateAvg = getAvergageInRange(
    transformedSessionGroups.map(day =>
      getDayAverage(day.sessions, 'strintFilteredStrideRateAvg'),
    ),
  );
  const strintFilteredStrideRateMax = getMaxInRange(
    transformedSessionGroups.map(day =>
      getDayMax(day.sessions, 'strintFilteredStrideRateMax'),
    ),
  );
  const balanceNormalized = getAvergageInRange(
    baselineGroups.map(
      day => getDayAverage(day.sessions, 'balanceNormalized') * 200,
    ),
  );

  return {
    totalStrides,
    totalTimeOnIce,
    speedAvg,
    speedMax,
    accelAvg,
    accelMax,
    strintFilteredStrideRateAvg,
    strintFilteredStrideRateMax,
    balanceNormalized,
    timeAboveTarget: hrZoneTime[3] + hrZoneTime[4],
  };
};

const getAllTimeComparisonSummaries = (
  playerSessions: PlayerSession[],
  baselines: Baseline[],
  isMetric = false,
): {
  firstAllTimeComparisonSummary: AllTimeComparisonSummary;
  lastAllTimeComparisonSummary: AllTimeComparisonSummary;
} | null => {
  const filteredSessions =
    getFirstAndLastAllTimeComparisonSessions(playerSessions);
  if (!filteredSessions) {
    return null;
  }
  const filteredBaselines =
    getFirstAndLastAllTimeComparisonBaselines(baselines);
  const {firstAllTimeComparisonSessions, lastAllTimeComparisonSessions} =
    filteredSessions;
  const firstAllTimeComparisonSummary: AllTimeComparisonSummary =
    getSummaryInRangeGroupedByDay(
      firstAllTimeComparisonSessions,
      filteredBaselines
        ? filteredBaselines.firstAllTimeComparisonBaselines
        : [],
      isMetric,
    );
  const lastAllTimeComparisonSummary: AllTimeComparisonSummary =
    getSummaryInRangeGroupedByDay(
      lastAllTimeComparisonSessions,
      filteredBaselines ? filteredBaselines.lastAllTimeComparisonBaselines : [],
      isMetric,
    );
  return {
    firstAllTimeComparisonSummary,
    lastAllTimeComparisonSummary,
  };
};

const getDateRangeSummaries = ({
  playerSessions,
  baselines,
  isMetric,
}: {
  playerSessions: PlayerSession[];
  baselines: Baseline[];
  isMetric: boolean;
}) => {
  const thisWeekSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 7,
    endDaysAgo: 0,
    isMetric,
  });
  const lastWeekSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 14,
    endDaysAgo: 7,
    isMetric,
  });
  const thisMonthSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 30,
    endDaysAgo: 0,
    isMetric,
  });
  const lastMonthSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 60,
    endDaysAgo: 30,
    isMetric,
  });
  const thisYearSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 365,
    endDaysAgo: 0,
    isMetric,
  });
  const lastYearSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: 730,
    endDaysAgo: 365,
    isMetric,
  });
  const firstSession: PlayerSession = (playerSessions ?? [])
    .filter(a => a.startTime)
    .sort((a, b) => {
      return (
        new Date(b.startTime!).getTime() - new Date(a.startTime!).getTime()
      );
    })
    .reverse()?.[0];
  const allTimeSummary = getSummaryInRange({
    playerSessions,
    baselines,
    startDaysAgo: firstSession?.startTime
      ? Math.ceil(
          DateTime.fromISO(firstSession.startTime).diff(
            DateTime.local().endOf('day'),
            'days',
          ).days * -1,
        )
      : 0,
    endDaysAgo: 0,
    isMetric,
  });
  const summaryData = getAllTimeComparisonSummaries(
    playerSessions,
    baselines,
    isMetric,
  );

  return {
    thisWeekSummary,
    lastWeekSummary,
    thisMonthSummary,
    lastMonthSummary,
    thisYearSummary,
    lastYearSummary,
    allTimeSummary,
    firstTwentyDaySummary: summaryData?.firstAllTimeComparisonSummary,
    lastTwentyDaySummary: summaryData?.lastAllTimeComparisonSummary,
  };
};

export {getDateRangeSummaries, getDayAverage, getDayMax, hrZoneTimeReducer};
