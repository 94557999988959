import axios, {AxiosPromise} from 'axios';
import {FileUploadStatus} from '../interfaces';

const s3Api = axios.create();

export function uploadToS3(
  preAuthUrl: string,
  file: File,
  onUploadProgress: (params: FileUploadStatus) => void,
) {
  return new Promise<AxiosPromise>((resolve, reject) => {
    try {
      s3Api.defaults.headers.common = {
        'Content-Type': `${file.type}`,
      };
      s3Api.defaults.onUploadProgress = progressEvent => {
        const percentComplete = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 100),
        );
        onUploadProgress({
          filename: file.name,
          percentComplete,
        });
      };
      s3Api
        .put(preAuthUrl, file)
        .then((response: any) => {
          resolve(response);
        })
        .catch((e: Error) => {
          reject(e);
        });
    } catch (e) {
      reject(e);
    }
  });
}
