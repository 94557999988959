import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppSelector} from '../../../store/hooks';
import {PlayerSession, SummaryInRange} from '../../../interfaces';
import {
  getDateRangeSummaries,
  getDayAverage,
  getDayMax,
  hrZoneTimeReducer,
} from '../../../services/teamPlayerDashboardService';

interface TeamPlayerData {
  value: string;
  avgData?: number[];
  avgDataBaseline?: number[];
  maxData: number[];
  maxDataBaseline?: number[];
  hrZoneData?: number[][];
}

const usePlayerData = () => {
  const [weekData, setWeekData] = useState<TeamPlayerData[]>([]);
  const [lastWeekData, setLastWeekData] = useState<TeamPlayerData[]>([]);
  const [monthData, setMonthData] = useState<TeamPlayerData[]>([]);
  const [lastMonthData, setLastMonthData] = useState<TeamPlayerData[]>([]);
  const [yearData, setYearData] = useState<TeamPlayerData[]>([]);
  const [lastYearData, setLastYearData] = useState<TeamPlayerData[]>([]);
  const [allTimeData, setAlltimeData] = useState<TeamPlayerData[]>([]);

  const playerSessions: PlayerSession[] | null = useAppSelector(
    state => state.playerSession?.playerSessions,
  );
  const baselines = useAppSelector(state => state.baseline?.baselines);
  const preferredUnits = useAppSelector(
    state => state.profile.profileDetail?.preferredUnits,
  );

  const {
    thisWeekSummary,
    lastWeekSummary,
    thisMonthSummary,
    lastMonthSummary,
    thisYearSummary,
    lastYearSummary,
    allTimeSummary,
    firstTwentyDaySummary,
    lastTwentyDaySummary,
  } = useMemo(
    () =>
      getDateRangeSummaries({
        playerSessions: playerSessions ?? [],
        baselines: baselines ?? [],
        isMetric: preferredUnits === 'metric',
      }),
    [baselines, playerSessions, preferredUnits],
  );

  const generateTimePeriodGraphData = useCallback(
    (rangeData: SummaryInRange) => {
      const dataByDay = rangeData.dateArray
        .map(day => {
          const avgSpeed = getDayAverage(day, 'speedAvg');
          const maxSpeed = getDayMax(day, 'speedMax');
          const avgAccel = getDayAverage(day, 'accelAvg');
          const maxAccel = getDayMax(day, 'accelMax');
          const avgStrint = getDayAverage(day, 'strintFilteredStrideRateAvg');
          const maxStrint = getDayMax(day, 'strintFilteredStrideRateMax');
          const activeTime =
            day.reduce(
              (acc: number, value: PlayerSession) =>
                acc + (value.activeTime ?? 0),
              0,
            ) / 60;
          const strideCount = day.reduce(
            (acc: number, value: PlayerSession) =>
              acc +
              (value.strideCountLeft ?? 0) +
              (value.strideCountRight ?? 0),
            0,
          );
          const hrZoneTime = day.reduce(hrZoneTimeReducer, [0, 0, 0, 0, 0]);
          return {
            avgSpeed,
            maxSpeed,
            avgAccel,
            maxAccel,
            avgStrint,
            maxStrint,
            activeTime,
            strideCount,
            hrZoneTime,
          };
        })
        .reverse();
      const baselineDataByDay = rangeData.baselineDateArray
        .map(day => {
          const balanceRaw = getDayMax(day, 'balanceNormalized');
          const balanceMax = balanceRaw * 200;
          const avgSpeedBaseline = getDayAverage(day, 'speedAvg');
          const maxSpeedBaseline = getDayMax(day, 'speedMax');
          const avgAccelBaseline = getDayAverage(day, 'accelAvg');
          const maxAccelBaseline = getDayMax(day, 'accelMax');
          const avgStrintBaseline = getDayAverage(
            day,
            'strintFilteredStrideRateAvg',
          );
          const maxStrintBaseline = getDayMax(
            day,
            'strintFilteredStrideRateMax',
          );
          return {
            balanceMax,
            avgSpeedBaseline,
            maxSpeedBaseline,
            avgAccelBaseline,
            maxAccelBaseline,
            avgStrintBaseline,
            maxStrintBaseline,
          };
        })
        .reverse();
      const allSpeedData: TeamPlayerData = {
        value: 'speed',
        avgData: dataByDay.map(d => d.avgSpeed),
        avgDataBaseline: baselineDataByDay.map(d => d.avgSpeedBaseline),
        maxData: dataByDay.map(d => d.maxSpeed),
        maxDataBaseline: baselineDataByDay.map(d => d.maxSpeedBaseline),
      };
      const allAccelData: TeamPlayerData = {
        value: 'accel',
        avgData: dataByDay.map(d => d.avgAccel),
        avgDataBaseline: baselineDataByDay.map(d => d.avgAccelBaseline),
        maxData: dataByDay.map(d => d.maxAccel),
        maxDataBaseline: baselineDataByDay.map(d => d.maxAccelBaseline),
      };
      const allStrintData: TeamPlayerData = {
        value: 'strint',
        avgData: dataByDay.map(d => d.avgStrint),
        avgDataBaseline: baselineDataByDay.map(d => d.avgStrintBaseline),
        maxData: dataByDay.map(d => d.maxStrint),
        maxDataBaseline: baselineDataByDay.map(d => d.maxStrintBaseline),
      };
      const allBalanceData: TeamPlayerData = {
        value: 'balance',
        avgData: [],
        maxData: baselineDataByDay.map(d => d.balanceMax),
      };
      const allStrideCount: TeamPlayerData = {
        value: 'strideCount',
        avgData: [],
        maxData: dataByDay.map(d => d.strideCount),
      };
      const allActiveTime: TeamPlayerData = {
        value: 'activeTime',
        avgData: [],
        maxData: dataByDay.map(d => d.activeTime),
      };
      const allHeartRate: TeamPlayerData = {
        value: 'heartRate',
        avgData: [],
        maxData: [],
        hrZoneData: dataByDay.map(d => d.hrZoneTime),
      };

      return [
        allSpeedData,
        allAccelData,
        allStrintData,
        allBalanceData,
        allStrideCount,
        allActiveTime,
        allHeartRate,
      ];
    },
    [],
  );

  useEffect(() => {
    const genWeekData = generateTimePeriodGraphData(thisWeekSummary);
    setWeekData(genWeekData);
    const genLastWeekData = generateTimePeriodGraphData(lastWeekSummary);
    setLastWeekData(genLastWeekData);

    const genMonthData = generateTimePeriodGraphData(thisMonthSummary);
    setMonthData(genMonthData);
    const genLastMonthData = generateTimePeriodGraphData(lastMonthSummary);
    setLastMonthData(genLastMonthData);

    const genYearData = generateTimePeriodGraphData(thisYearSummary);
    const genLastYearData = generateTimePeriodGraphData(lastYearSummary);
    setYearData(genYearData);
    setLastYearData(genLastYearData);

    const genAllTimeData = generateTimePeriodGraphData(allTimeSummary);
    setAlltimeData(genAllTimeData);
  }, [
    allTimeSummary,
    generateTimePeriodGraphData,
    lastMonthSummary,
    lastWeekSummary,
    lastYearSummary,
    thisMonthSummary,
    thisWeekSummary,
    thisYearSummary,
  ]);

  return {
    weekData,
    lastWeekData,
    monthData,
    lastMonthData,
    yearData,
    lastYearData,
    allTimeData,
    thisWeekSummary,
    lastWeekSummary,
    thisMonthSummary,
    lastMonthSummary,
    thisYearSummary,
    lastYearSummary,
    allTimeSummary,
    firstTwentyDaySummary,
    lastTwentyDaySummary,
  };
};

export default usePlayerData;
