import React, {useMemo} from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory';
import {Box} from '@mui/material';
import {SizeMe} from 'react-sizeme';
import {createTrend} from '../../../services/mathService';
import {GRAPH_YELLOW, ORANGE_COLOR} from '../../../styles';
import {
  getWeekTickFormatFunction,
  toolTipStyle,
  xAxisWithTicksStyle,
  yAxisStyle,
} from '../../../services/graphService';
import {useTranslation} from 'react-i18next';
import {TimeValue} from '../../../enums';

interface Props {
  currentPeriodData: number[];
  historicPeriodData: number[];
  baselineData?: number[];
  metric: string;
  range: TimeValue;
  dataValue: string;
}

const TotalEffortLineChart: React.FC<Props> = props => {
  const {t} = useTranslation();
  const {
    range,
    metric,
    dataValue,
    currentPeriodData,
    historicPeriodData,
    baselineData,
  } = props;

  const decimals = useMemo(() => {
    switch (dataValue) {
      case 'accel':
        return 2;
      default:
        return 0;
    }
  }, [dataValue]);

  const {
    repackagedCurrentData,
    repackagedHistoricData,
    repackagedBaselineData,
  } = useMemo(() => {
    let newIndex = 0;
    if (range === TimeValue.year && !historicPeriodData.some(hpd => hpd > 0)) {
      newIndex = currentPeriodData.findIndex(cpd => cpd > 0);
    }
    return {
      repackagedCurrentData: currentPeriodData.slice(newIndex),
      repackagedHistoricData: historicPeriodData.slice(newIndex),
      repackagedBaselineData: baselineData?.slice(newIndex),
    };
  }, [baselineData, currentPeriodData, historicPeriodData, range]);

  const trendData1 = createTrend(
    (repackagedBaselineData ?? repackagedCurrentData)
      .map((point, index) => {
        if (point === 0) {
          return null;
        }
        return {
          x: index,
          y: point,
        };
      })
      .filter(x => x),
    'x',
    'y',
  );

  const combinedData = [
    ...repackagedCurrentData,
    ...repackagedHistoricData,
  ].filter(cd => cd > 0);

  const minValue = combinedData.length ? Math.min(...combinedData) : 0;
  const maxValue = combinedData.length ? Math.max(...combinedData) : 0;
  const adjustedMinValue = minValue - Math.abs(minValue * 0.1);
  const adjustedMaxValue = maxValue + Math.abs(maxValue * 0.1);

  return (
    <Box>
      <SizeMe>
        {({size}) => (
          <VictoryChart
            width={size.width ? size.width - 20 : 1200}
            height={size.width && size.width > 500 ? 400 : 300}
            padding={{left: 30, right: 70, top: 20, bottom: 40}}
            domain={
              !!adjustedMaxValue && !!adjustedMinValue
                ? {
                    y: [
                      adjustedMinValue > 0 ? adjustedMinValue : 0,
                      adjustedMaxValue,
                    ],
                  }
                : undefined
            }>
            <VictoryGroup>
              <VictoryLine
                data={repackagedCurrentData
                  .map((point, index) => {
                    if (point === 0) {
                      return {
                        x: null,
                        y: null,
                      };
                    }
                    return {
                      x: index,
                      y: point,
                    };
                  })
                  .filter(x => x)}
                style={{data: {stroke: ORANGE_COLOR}}}
              />
              <VictoryScatter
                data={repackagedCurrentData.map(point => {
                  if (point === 0) {
                    return null;
                  }
                  return point;
                })}
                style={{data: {fill: ORANGE_COLOR}}}
                size={2}
                labels={({datum}) => `${datum._y.toFixed(3)}${metric}`}
                labelComponent={<VictoryTooltip style={toolTipStyle} />}
              />
              {trendData1.yStart && trendData1.slope ? (
                <VictoryLine
                  data={[
                    {
                      x: 0,
                      y: trendData1.yStart,
                    },
                    {
                      x: repackagedCurrentData.length - 1,
                      y:
                        trendData1.slope * (repackagedCurrentData.length - 1) +
                        trendData1.yStart,
                    },
                  ]}
                  style={{data: {stroke: 'white', opacity: 0.8}}}
                />
              ) : null}
              <VictoryLine
                data={repackagedHistoricData
                  .map((point, index) => {
                    if (point === 0) {
                      return {
                        x: null,
                        y: null,
                      };
                    }
                    return {
                      x: index,
                      y: point,
                    };
                  })
                  .filter(x => x)}
                style={{data: {stroke: GRAPH_YELLOW}}}
              />
              <VictoryScatter
                data={repackagedHistoricData.map(point => {
                  if (point === 0) {
                    return null;
                  }
                  return point;
                })}
                style={{data: {fill: GRAPH_YELLOW}}}
                size={2}
                // TODO why isn't there a type for this? I think this is a types error with Victory
                labels={({datum}) => `${datum._y.toFixed(3)}${metric}`}
                labelComponent={<VictoryTooltip style={toolTipStyle} />}
              />
            </VictoryGroup>
            {range === 'week' ? (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(7).keys()]}
                tickFormat={getWeekTickFormatFunction(t, false)}
              />
            ) : (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(repackagedCurrentData.length).keys()]}
                tickFormat={tick =>
                  tick === 0
                    ? t('general.daysAgo', {
                        numberOfDays: repackagedCurrentData.length,
                      })
                    : tick === repackagedCurrentData.length - 1
                      ? t('general.today')
                      : ''
                }
                tickLabelComponent={
                  <VictoryLabel
                    textAnchor={data => (data.index === 0 ? 'start' : 'end')}
                  />
                }
              />
            )}
            <VictoryAxis
              dependentAxis
              tickFormat={tick =>
                tick.toFixed(decimals) !== (0).toFixed(decimals)
                  ? `${tick.toFixed(decimals)}${metric}`
                  : ''
              }
              orientation="right"
              style={yAxisStyle}
            />
          </VictoryChart>
        )}
      </SizeMe>
    </Box>
  );
};

export default TotalEffortLineChart;
