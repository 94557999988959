import React from 'react';
import { AmplifyProvider, Authenticator, Placeholder, Theme } from '@aws-amplify/ui-react';
import {Amplify, Auth, I18n } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import Color from 'color';
import HeliosLogo from './images/HELIOS_Logo_and_Wordmark_White_20220621.png';
import { Box, Link, Typography } from '@mui/material';
import Main from './components/Main';

I18n.setLanguage('en');

const signInFormFields = {
  signIn: {
    username: {
      placeholder: 'Email Address',
      label: '',
    },
    password: {
      placeholder: 'Password',
      label: '',
    },
  },
  signUp: {
    username: {
      placeholder: 'Email Address',
      label: '',
    },
    password: {
      placeholder: 'Password',
      label: '',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email',
      label: 'Email Address',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Code',
      label: '',
      isRequired: true,
    },
    password: {
      placeholder: 'New Password',
      label: '',
    },
    confirm_password: {
      placeholder: 'Confirm Password',
      label: '',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: '',
      placeholder: 'Code',
      isRequired: true,
    },
  },
};

Amplify.configure({
  Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  },
});

function App() {
  const services = {
    async handleSignIn(formData: any) {
      let { username, password } = formData;
      // force lowercase email username
      username = username.toLowerCase();
      return Auth.signIn({
        username,
        password,
      });
    },
  };

  const components = {
    Header() {  
      return (
        <Box sx={{mb: 2, textAlign: 'center'}}>
          <img src={HeliosLogo} alt="Helios Logo" width={150} />
        </Box>
      );
    },
    Footer() {
      return (
        <Box sx={{mt: 2, textAlign: 'center'}}>
          <Typography variant='body1' color={"#fff"}>
            Return to&nbsp;
            <Link color={"#fff"} href="https://www.helioshockey.com">www.helioshockey.com</Link>
          </Typography>
        </Box>
      )
    },
  }

  const theme: Theme = {
    name: 'Auth Theme',
    
    tokens: {
      components: {
        authenticator: {
          modal: {
            backgroundColor: {
              value: 'black',
            },
          },
        },
        button: {
          primary: {
            backgroundColor: {
              value: '#F26014',
            },
            _hover: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            },
            _focus: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            },
            _active: {
              backgroundColor: {
                value: Color('#F26014').darken(.2).string(),
              },
            }
          },
        },
      },
    },
  };

  return (
    <AmplifyProvider theme={theme}>
      <Authenticator
        components={components}
        formFields={signInFormFields}
        services={services}
        hideSignUp={true}
        variation="modal"  
      >
        {({ signOut, user }) => (
          <Main user={user} />
        )}
      </Authenticator>
    </AmplifyProvider>
  );
}

export default App;
