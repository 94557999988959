import {Box, Container, styled} from '@mui/material';
import {DateTime} from 'luxon';
import React, {useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {getTeamSessionDetailAsync} from '../../store/teamSessionSlice';
import BackButton from '../shared/BackButton';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import PageSubtitle from '../shared/PageSubtitle';
import PageTitle from '../shared/PageTitle';
import PlayerSessionsList from '../shared/PlayerSessionsList';
import SessionVideoManager from '../shared/SessionVideoManager';
import TeamShiftUtilization from '../shared/TeamShiftUtilization';
import {Roles} from '../../enums';
import CreateLostTeamSessionPlayerSession from './CreateLostTeamSessionPlayerSession';
import {PlayerSession, Team, TeamSessionSessionEvent} from '../../interfaces';
import ExportSessionModal from '../shared/ExportSessionModal';
import SubtitleContainer from '../shared/SubtitleContainer';
import PracticeEfficiency from '../shared/PracticeEfficiency/PracticeEfficiency';
import DownloadSessionJson from '../Admin/DownloadSessionJson';

interface Props {}

const StyledSessionType = styled('span')(() => ({
  display: 'block',
  textTransform: 'capitalize',
}));

const TeamSessionDetail: React.FC<Props> = () => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    profile: state.profile,
    teamSession: state.teamSession,
  }));
  const role = useAppSelector(state => state.profile?.profile?.role);
  const teamSession = useAppSelector(state => state.teamSession?.teamSession);
  const teams = useAppSelector(state => state.team?.teams);
  const dispatch = useAppDispatch();
  const {teamId, teamSessionId} = useParams();
  const teamSessionIdInt = useMemo(
    () => parseInt(teamSessionId ?? ''),
    [teamSessionId],
  );
  const teamIdInt = useMemo(() => parseInt(teamId ?? ''), [teamId]);
  const activeTeam = useMemo(
    () => teams.find((t: Team) => t.id === teamIdInt),
    [teams, teamIdInt],
  );

  useEffect(() => {
    dispatch(getTeamSessionDetailAsync(teamSessionIdInt));
  }, [dispatch, teamSessionIdInt]);

  const title = t('navigation.pageTitle', {
    pageName: t('pages.teamSessionDetail'),
  });
  const sessionType = currentState.teamSession?.teamSession?.type;

  if (
    currentState.teamSession.status === 'loading' ||
    !currentState.teamSession?.teamSession
  ) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>{t('pages.teamSessionDetail')}</PageTitle>
        <BackButton
          navigationUrl={
            currentState.teamSession?.teamSession?.teamSessionGroup
              ? `/teams/${teamId}/teamsessiongroups/${currentState.teamSession?.teamSession?.teamSessionGroup?.id}`
              : `/teams/${teamId}/teamsessions`
          }>
          {t('navigation.back', {
            pageName: t(
              currentState.teamSession?.teamSession?.teamSessionGroup
                ? 'pages.teamSessionGroupDetail'
                : 'pages.teamSessions',
            ),
          })}
        </BackButton>
        <SubtitleContainer>
          <PageSubtitle sx={{flexGrow: 1, mr: 2}}>
            <StyledSessionType>{sessionType}</StyledSessionType>
            {DateTime.fromISO(
              currentState.teamSession?.teamSession?.startTime,
            ).toFormat('EEEE M/d/yyyy h:mm a')}
          </PageSubtitle>
          <ExportSessionModal
            buttonSx={{mb: 2, alignSelf: 'center'}}
            teamSessionId={teamSessionIdInt}
            teamSessionStartTime={teamSession?.startTime}
            teamSessionHasPuckDropEvents={
              (teamSession?.teamSessionSessionEvents ?? []).filter(
                (e: TeamSessionSessionEvent) =>
                  [
                    'PUCK_DROP_PERIOD_1',
                    'PUCK_DROP_PERIOD_2',
                    'PUCK_DROP_PERIOD_3',
                    'PUCK_DROP_OVERTIME',
                  ].indexOf(e.sessionEvent?.code as string) !== -1,
              ).length > 0
            }
            teamHasHudlConfig={activeTeam?.hasHudlConfig}
            teamHasSpiideoConfig={activeTeam?.hasSpiideoConfig}
            teamHasCatapultConfig={activeTeam?.hasCatapultConfig}
          />
        </SubtitleContainer>
        {role === Roles.admin && teamSession?.playerSessions?.length > 0 && (
          <Box sx={{display: 'flex', flexDirection: 'row', marginX: -2}}>
            <CreateLostTeamSessionPlayerSession
              team={activeTeam}
              teamSession={teamSession}
              refresh={() =>
                dispatch(getTeamSessionDetailAsync(teamSessionIdInt))
              }
            />
            <DownloadSessionJson
              fileTitle={`teamsession_${teamSession.id}_${DateTime.fromISO(
                teamSession.startTime,
              ).toFormat('yyyyMMddhhmmss')}`}
              playerSessionIds={teamSession.playerSessions.map(
                (ps: PlayerSession) => ps.id,
              )}
            />
          </Box>
        )}
        <TeamShiftUtilization
          teamSessions={[currentState.teamSession?.teamSession] ?? []}
        />
        {sessionType !== 'game' && (
          <PracticeEfficiency
            playerSessions={
              currentState.teamSession?.teamSession?.playerSessions || []
            }
            teamSessionStartTime={
              currentState.teamSession?.teamSession?.startTime
            }
            teamSessionEndTime={currentState.teamSession?.teamSession?.endTime}
            practiceEfficiencyMax={activeTeam?.practiceEfficiencyMax}
          />
        )}

        <PageSubtitle>{t('video.instantVideoTitle')}</PageSubtitle>
        <SessionVideoManager
          sessionVideos={currentState.teamSession?.teamSession?.sessionVideos}
          sessionType="team"
          teamId={teamIdInt}
          sessionId={teamSessionIdInt}
        />

        <PageSubtitle>{t('general.players')}</PageSubtitle>
        <PlayerSessionsList
          navigateUrlPrefix={`/teams/${teamId}/teamsessions/${teamSessionId}/playersessions`}
          playerSessions={
            currentState.teamSession?.teamSession?.playerSessions ?? []
          }
          showProfile={true}
          sortBy="teamPositionThenNameAsc"
        />
      </Container>
    </>
  );
};

export default TeamSessionDetail;
