import {Button, Paper, Typography} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getPlayerSessionDocuments} from '../../api/exportAPI';

interface Props {
  playerSessionIds: number[];
  fileTitle: string;
}

const DownloadSessionJson: React.FC<Props> = ({
  playerSessionIds,
  fileTitle,
}) => {
  const [downloading, setDownloading] = useState(false);
  const {t} = useTranslation();

  const downloadJsonFile = useCallback(async () => {
    setDownloading(true);
    try {
      await getPlayerSessionDocuments({playerSessionIds, fileTitle});
    } catch (err) {
      console.error(err);
      window.alert(t('admin.errorJson'));
      setDownloading(false);
    }
    setDownloading(false);
  }, [fileTitle, playerSessionIds, t]);

  return (
    <Paper sx={{mb: 2, mx: 2, p: 4, flex: 0.5}}>
      <Typography variant="h4">
        {`${t('admin.adminOnly')}: ${t('admin.downloadJson')}`}
      </Typography>
      <Button
        disabled={downloading}
        sx={{mt: 2}}
        variant="outlined"
        onClick={downloadJsonFile}>
        {t('general.download')}
      </Button>
    </Paper>
  );
};

export default DownloadSessionJson;
