import React, {useMemo, useState} from 'react';
import {
  Box,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {getSpeedUnit} from '../../../services/measurementService';
import TotalEffortLineChart from './TotalEffortLineChart';
import {ORANGE_COLOR} from '../../../styles';
import {useTranslation} from 'react-i18next';
import {TimeValue} from '../../../enums';
import usePlayerData from './PlayerData.hooks';
import {useAppSelector} from '../../../store/hooks';
import TotalEffortHeartRateChart from './TotalEffortHeartRateChart';
import TotalEffortInsights from './TotalEffortInsights';
import TotalEffortLegend from './TotalEffortLegend';

export interface DataValue {
  value: string;
  label: string;
}

const dataValueOptions: DataValue[] = [
  {
    value: 'speed',
    label: 'Stride Speed',
  },
  {
    value: 'accel',
    label: 'Explosiveness',
  },
  {
    value: 'strint',
    label: 'Agility',
  },
  {
    value: 'balance',
    label: 'Balance',
  },
  {
    value: 'strideCount',
    label: 'Strides',
  },
  {
    value: 'activeTime',
    label: 'Active Time',
  },
  {
    value: 'heartRate',
    label: 'Heart Rate',
  },
];

const usesAvgMaxToggle = ['speed', 'accel', 'strint'];

interface Props {
  playerName: string;
}

const PlayerPlotChart: React.FC<Props> = ({playerName}) => {
  const [dataValue, setDataValue] = useState(dataValueOptions[0]);
  const [timeValue, setTimeValue] = useState<TimeValue>(TimeValue.week);
  const [avgMax, setAvgMax] = useState<'avgData' | 'maxData'>('avgData');
  const {t} = useTranslation();
  const preferredUnits = useAppSelector(
    state => state.profile.profileDetail?.preferredUnits,
  );
  const {
    weekData,
    lastWeekData,
    monthData,
    lastMonthData,
    yearData,
    lastYearData,
    allTimeData,
    thisWeekSummary,
    lastWeekSummary,
    thisMonthSummary,
    lastMonthSummary,
    thisYearSummary,
    lastYearSummary,
    allTimeSummary,
    firstTwentyDaySummary,
    lastTwentyDaySummary,
  } = usePlayerData();

  const metric = useMemo(() => {
    switch (dataValue.value) {
      case 'speed':
        return ` ${getSpeedUnit(preferredUnits === 'metric')}`;
      case 'accel':
        return ' g';
      case 'strint':
        return ' spm';
      case 'hr':
        return ' bpm';
      case 'activeTime':
        return ' m';
      case 'balance':
        return '%';
      case 'strideCount':
      default:
        return '';
    }
  }, [dataValue, preferredUnits]);

  const {allCurrentTimePeriodData, allHistoricTimePeriodData} = useMemo(() => {
    const currentTimePeriodData = [];
    const historicTimePeriodData = [];
    switch (timeValue) {
      case TimeValue.week:
        currentTimePeriodData.push(...weekData);
        historicTimePeriodData.push(...lastWeekData);
        break;
      case TimeValue.month:
        currentTimePeriodData.push(...monthData);
        historicTimePeriodData.push(...lastMonthData);
        break;
      case TimeValue.year:
        currentTimePeriodData.push(...yearData);
        historicTimePeriodData.push(...lastYearData);
        break;
      default:
        currentTimePeriodData.push(...allTimeData);
    }
    return {
      allCurrentTimePeriodData: currentTimePeriodData,
      allHistoricTimePeriodData: historicTimePeriodData,
    };
  }, [
    allTimeData,
    lastMonthData,
    lastWeekData,
    lastYearData,
    monthData,
    timeValue,
    weekData,
    yearData,
  ]);

  const {currentPeriodData, historicPeriodData, heartRateData, trendLineData} =
    useMemo(() => {
      const selectedData = allCurrentTimePeriodData.find(
        pd => pd.value === dataValue.value,
      );
      if (selectedData?.value === 'heartRate') {
        return {
          currentPeriodData: [],
          historicPeriodData: [],
          heartRateData: selectedData.hrZoneData,
        };
      }
      const selectedHistoricData = allHistoricTimePeriodData.find(
        pd => pd.value === dataValue.value,
      );
      const avgOrMax = usesAvgMaxToggle.includes(dataValue.value)
        ? avgMax
        : 'maxData';
      const currentPeriodData = selectedData ? selectedData[avgOrMax] : [];
      const historicPeriodData = selectedHistoricData
        ? selectedHistoricData[avgOrMax]
        : [];
      return {
        currentPeriodData: currentPeriodData ?? [],
        historicPeriodData: historicPeriodData ?? [],
        heartRateData: null,
        trendLineData:
          avgOrMax === 'avgData'
            ? selectedData?.avgDataBaseline
            : selectedData?.maxDataBaseline,
      };
    }, [
      allCurrentTimePeriodData,
      allHistoricTimePeriodData,
      dataValue.value,
      avgMax,
    ]);

  const currentSummary = useMemo(() => {
    switch (timeValue) {
      case TimeValue.week:
        return thisWeekSummary;
      case TimeValue.month:
        return thisMonthSummary;
      case TimeValue.year:
        return thisYearSummary;
      default:
        return allTimeSummary;
    }
  }, [
    allTimeSummary,
    thisMonthSummary,
    thisWeekSummary,
    thisYearSummary,
    timeValue,
  ]);

  const historicSummary = useMemo(() => {
    switch (timeValue) {
      case TimeValue.week:
        return lastWeekSummary;
      case TimeValue.month:
        return lastMonthSummary;
      case TimeValue.year:
        return lastYearSummary;
      default:
        return null;
    }
  }, [lastMonthSummary, lastWeekSummary, lastYearSummary, timeValue]);

  const decimals = useMemo(() => {
    switch (dataValue.value) {
      case 'accel':
        return 2;
      case 'speed':
        return 1;
      default:
        return 0;
    }
  }, [dataValue]);

  return (
    <Box>
      <Typography sx={{color: ORANGE_COLOR, ml: 2}} variant="h5">
        {t('statistics.performanceAnalysis')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}>
        <Box sx={{flex: 0.25, margin: 1}}>
          <Select
            sx={{
              width: '100%',
              fontWeight: 700,
              fontSize: '1.2rem',
              textTransform: 'uppercase',
            }}
            labelId="team-select-label"
            value={dataValue.value}
            onChange={v => {
              const newValue = dataValueOptions.find(
                dv => dv.value === v.target.value,
              );
              if (newValue) {
                setDataValue(newValue);
              }
            }}>
            {dataValueOptions.map(dv => (
              <MenuItem
                key={dv.value}
                value={dv.value}
                sx={{textTransform: 'uppercase'}}>
                {dv.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{margin: 1}}>
          <ToggleButtonGroup
            value={timeValue}
            onChange={(_, v) => {
              if (v) {
                setTimeValue(v);
              }
            }}
            exclusive
            size="large">
            <ToggleButton value={TimeValue.week} key="week">
              {t('general.week')}
            </ToggleButton>
            <ToggleButton value={TimeValue.month} key="month">
              {t('general.month')}
            </ToggleButton>
            <ToggleButton value={TimeValue.year} key="year">
              {t('general.year')}
            </ToggleButton>
            <ToggleButton value={TimeValue.allTime} key="allTime">
              {t('general.allTime')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {usesAvgMaxToggle.includes(dataValue.value) && (
          <Box sx={{margin: 1}}>
            <ToggleButtonGroup
              value={avgMax}
              onChange={(_, v) => {
                if (v) {
                  setAvgMax(v);
                }
              }}
              exclusive
              size="large">
              <ToggleButton value="avgData" key="avg">
                Avg
              </ToggleButton>
              <ToggleButton value="maxData" key="max">
                Max
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        )}
      </Box>
      <Box>
        {dataValue.value === 'heartRate' && heartRateData ? (
          <TotalEffortHeartRateChart
            hrZoneTime={heartRateData}
            timeRange={timeValue}
            hrMin={currentSummary.hrMin}
            hrMax={currentSummary.hrMax}
            hrAvg={currentSummary.hrAvg}
          />
        ) : (
          <TotalEffortLineChart
            currentPeriodData={currentPeriodData}
            historicPeriodData={historicPeriodData}
            baselineData={trendLineData}
            metric={metric}
            range={timeValue}
            dataValue={dataValue.value}
          />
        )}
      </Box>
      <TotalEffortLegend
        dataValue={dataValue}
        timeValue={timeValue}
        usesAvgMaxToggle={usesAvgMaxToggle.includes(dataValue.value)}
        currentSummary={currentSummary}
        historicSummary={historicSummary}
        avgMax={avgMax}
        decimals={decimals}
        metric={metric}
      />
      <TotalEffortInsights
        currentSummary={currentSummary}
        historicSummary={historicSummary}
        dataValue={dataValue}
        timeValue={timeValue}
        avgMax={avgMax}
        metric={metric}
        decimals={decimals}
        firstTwentyDaySummary={firstTwentyDaySummary}
        lastTwentyDaySummary={lastTwentyDaySummary}
        playerName={playerName}
      />
    </Box>
  );
};

export default PlayerPlotChart;
