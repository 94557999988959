import {TFunction} from 'i18next';
import {TimeValue} from '../enums';
import {AllTimeComparisonSummary, SummaryInRange} from '../interfaces';

const getStatisticValues = (
  avgMax: 'avgData' | 'maxData',
  dataValue: string,
  currentSummary: SummaryInRange,
  historicSummary: SummaryInRange | null,
) => {
  let currentStatistic: number | null = null;
  let historicStatistic: number | null = null;
  const useAvg = avgMax === 'avgData';
  switch (dataValue) {
    case 'speed':
      currentStatistic = useAvg
        ? currentSummary.speedAvg
        : currentSummary.speedMax;
      if (historicSummary) {
        historicStatistic = useAvg
          ? historicSummary?.speedAvg
          : historicSummary?.speedMax;
      }
      break;
    case 'accel':
      currentStatistic = useAvg
        ? currentSummary.accelAvg
        : currentSummary.accelMax;
      if (historicSummary) {
        historicStatistic = useAvg
          ? historicSummary?.accelAvg
          : historicSummary?.accelMax;
      }
      break;
    case 'strint':
      currentStatistic = useAvg
        ? currentSummary.strintFilteredStrideRateAvg
        : currentSummary.strintFilteredStrideRateMax;
      if (historicSummary) {
        historicStatistic = useAvg
          ? historicSummary?.strintFilteredStrideRateAvg
          : historicSummary?.strintFilteredStrideRateMax;
      }
      break;
    case 'activeTime':
      currentStatistic = currentSummary.averageTimeOnIce / 60;
      if (historicSummary) {
        historicStatistic = historicSummary.averageTimeOnIce / 60;
      }
      break;
    case 'balance':
      currentStatistic = currentSummary.balanceNormalized;
      if (historicSummary) {
        historicStatistic = historicSummary.balanceNormalized;
      }
      break;
    case 'strideCount':
      currentStatistic = currentSummary.averageStrides;
      if (historicSummary) {
        historicStatistic = historicSummary.averageStrides;
      }
      break;
  }
  return {currentStatistic, historicStatistic};
};

const getComparativeText = (
  timeValue: TimeValue,
  currentStatistic: number | null,
  historicStatistic: number | null,
  dataValue: string,
  playerName: string,
  t: TFunction<'translation', undefined, 'translation'>,
) => {
  if (
    (!currentStatistic && currentStatistic !== 0) ||
    (!historicStatistic && historicStatistic !== 0)
  ) {
    return '';
  }
  const timePeriod =
    timeValue === TimeValue.week
      ? t('general.week').toLowerCase()
      : timeValue === TimeValue.month
        ? t('general.month').toLowerCase()
        : t('general.year').toLowerCase();
  switch (dataValue) {
    case 'speed':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.speedGreater')
            : t('totalEffort.speedLess'),
        period: timePeriod,
      });
    case 'accel':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.accelGreater')
            : t('totalEffort.accelLess'),
        period: timePeriod,
      });
    case 'strint':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.agilityGreater')
            : t('totalEffort.agilityLess'),
        period: timePeriod,
      });
    case 'activeTime':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.moreActiveTime')
            : t('totalEffort.lessActiveTime'),
        period: timePeriod,
      });
    case 'balance':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.balanceGreater')
            : t('totalEffort.balanceLess'),
        period: timePeriod,
      });
    case 'strideCount':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.moreStrides')
            : t('totalEffort.fewerStrides'),
        period: timePeriod,
      });
    case 'heartRate':
      return t('totalEffort.youComparisonPeriod', {
        name: playerName,
        comparison:
          currentStatistic > historicStatistic
            ? t('totalEffort.moreTimeHR')
            : t('totalEffort.lessTimeHR'),
        period: timePeriod,
      });
    default:
      return '';
  }
};

const getAllTimeComparison = (
  avgMax: 'avgData' | 'maxData',
  current: AllTimeComparisonSummary,
  historic: AllTimeComparisonSummary | null,
  dataValue: string,
  playerName: string,
  t: TFunction<'translation', undefined, 'translation'>,
) => {
  const useAvg = avgMax === 'avgData';
  let currentAllTimeStat = null;
  let prevAllTimeStat = null;
  switch (dataValue) {
    case 'speed':
      currentAllTimeStat = useAvg ? current.speedAvg : current.speedMax;
      prevAllTimeStat = useAvg ? historic?.speedAvg : historic?.speedMax;
      return {
        currentAllTimeStat,
        prevAllTimeStat,
        allTimeText: t('totalEffort.overallComparison', {
          name: playerName,
          comparison:
            currentAllTimeStat > (prevAllTimeStat ?? 0)
              ? t('totalEffort.speedGreater')
              : t('totalEffort.speedLess'),
        }),
      };
    case 'accel':
      currentAllTimeStat = useAvg ? current.accelAvg : current.accelMax;
      prevAllTimeStat = useAvg ? historic?.accelAvg : historic?.accelMax;
      return {
        currentAllTimeStat,
        prevAllTimeStat,
        allTimeText: t('totalEffort.overallComparison', {
          name: playerName,
          comparison:
            currentAllTimeStat > (prevAllTimeStat ?? 0)
              ? t('totalEffort.accelGreater')
              : t('totalEffort.accelLess'),
        }),
      };
    case 'strint':
      currentAllTimeStat = useAvg
        ? current.strintFilteredStrideRateAvg
        : current.strintFilteredStrideRateMax;
      prevAllTimeStat = useAvg
        ? historic?.strintFilteredStrideRateAvg
        : historic?.strintFilteredStrideRateMax;
      return {
        currentAllTimeStat,
        prevAllTimeStat,
        allTimeText: t('totalEffort.overallComparison', {
          name: playerName,
          comparison:
            currentAllTimeStat > (prevAllTimeStat ?? 0)
              ? t('totalEffort.agilityGreater')
              : t('totalEffort.agilityLess'),
        }),
      };
    case 'balance':
      currentAllTimeStat = current.balanceNormalized;
      prevAllTimeStat = historic?.balanceNormalized;
      return {
        currentAllTimeStat,
        prevAllTimeStat,
        allTimeText: t('totalEffort.overallComparison', {
          name: playerName,
          comparison:
            currentAllTimeStat > (prevAllTimeStat ?? 0)
              ? t('totalEffort.balanceGreater')
              : t('totalEffort.balanceLess'),
        }),
      };
    case 'heartRate':
      currentAllTimeStat = current.timeAboveTarget;
      prevAllTimeStat = historic?.timeAboveTarget;
      return {
        currentAllTimeStat,
        prevAllTimeStat,
        allTimeText: t('totalEffort.overallComparison', {
          name: playerName,
          comparison:
            currentAllTimeStat > (prevAllTimeStat ?? 0)
              ? t('totalEffort.moreTimeHR')
              : t('totalEffort.lessTimeHR'),
        }),
      };
    default:
      return {};
  }
};

export {getComparativeText, getStatisticValues, getAllTimeComparison};
