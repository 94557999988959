// original source: https://github.com/pilovm/multithreaded-uploader/blob/master/frontend/uploader.js
import * as Sentry from '@sentry/react';

export class Uploader {
  chunkSize: number;
  threadsQuantity: number;
  file: File;
  fileName: string;
  aborted: boolean;
  uploadedSize: number;
  progressCache: Record<number, number>;
  activeConnections: Record<number, XMLHttpRequest>;
  parts: {PartNumber: number; signedUrl: string}[];
  uploadedParts: {PartNumber: number; ETag: string}[];
  fileId: string;
  fileKey: string;
  onProgressFn: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;

  onErrorFn: (error: unknown) => void;
  onCompleteFn: (data: {
    fileId: string;
    fileKey: string;
    parts: {PartNumber: number; ETag: string}[];
  }) => void;

  constructor(options: {
    chunkSize: number;
    threadsQuantity?: number;
    file: File;
    fileName: string;
    parts: {PartNumber: number; signedUrl: string}[];
    uploadId: string;
    key: string;
  }) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize;
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.file = options.file;
    this.fileName = options.fileName;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = options.parts;
    this.uploadedParts = [];
    this.fileId = options.uploadId;
    this.fileKey = options.key;
    this.onProgressFn = () => {};
    this.onErrorFn = () => {};
    this.onCompleteFn = () => {};
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      this.sendNext();
    } catch (error) {
      await this.complete(error as Error);
    }
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity || this.aborted) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        if (!this.aborted) {
          this.sendNext();
        }
      };

      const attemptUpload = (retryCount = 0) => {
        if (this.aborted) return;

        this.sendChunk(chunk, part, sendChunkStarted)
          .then(() => {
            if (!this.aborted) {
              this.sendNext();
            }
          })
          .catch(error => {
            if (retryCount < 10) {
              console.log('Retrying upload', 'retryCount', retryCount);
              setTimeout(() => {
                attemptUpload(retryCount + 1);
              }, 1000);
            } else {
              this.parts.push(part);
              this.abort();
              this.complete(error);
            }
          });
      };

      attemptUpload();
    }
  }

  async complete(error: Error | null = null) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      Sentry.captureException(error);
      this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      this.onCompleteFn({
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      });
    }
  }

  sendChunk(
    chunk: Blob,
    part: {PartNumber: number; signedUrl: string},
    sendChunkStarted: () => void,
  ) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then(status => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  handleProgress(part: number, event: ProgressEvent) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);

      const total = this.file.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn({
        sent,
        total,
        percentage,
      });
    }
  }

  upload(
    file: Blob,
    part: {PartNumber: number; signedUrl: string},
    sendChunkStarted: () => void,
  ) {
    // uploading each part with its pre-signed URL
    return new Promise<number>((resolve, reject) => {
      if (this.fileId && this.fileKey) {
        const xhr = (this.activeConnections[part.PartNumber - 1] =
          new XMLHttpRequest());

        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.PartNumber - 1,
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
            }
          }
        };

        xhr.onerror = error => {
          reject(error);
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'));
          delete this.activeConnections[part.PartNumber - 1];
        };

        xhr.send(file);
      }
    });
  }

  onProgress(
    onProgress: (progress: {
      sent: number;
      total: number;
      percentage: number;
    }) => void,
  ) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError: (err: unknown) => void) {
    this.onErrorFn = onError;
    return this;
  }

  onComplete(
    onComplete: (data: {
      fileId: string;
      fileKey: string;
      parts: {PartNumber: number; ETag: string}[];
    }) => void,
  ) {
    this.onCompleteFn = onComplete;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach(id => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
