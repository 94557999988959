import React, {useMemo} from 'react';
import {
  getAllTimeComparison,
  getComparativeText,
  getStatisticValues,
} from '../../../services/playerPlotChartService';
import {AllTimeComparisonSummary, SummaryInRange} from '../../../interfaces';
import {useTranslation} from 'react-i18next';
import {TimeValue} from '../../../enums';
import InsightCard from './InsightCard';
import BrainIcon from '../../../images/BrainIcon';
import {DataValue} from './PlayerPlotChart';

interface Props {
  currentSummary: SummaryInRange;
  historicSummary: SummaryInRange | null;
  dataValue: DataValue;
  timeValue: TimeValue;
  avgMax: 'avgData' | 'maxData';
  metric: string;
  decimals: number;
  firstTwentyDaySummary?: AllTimeComparisonSummary;
  lastTwentyDaySummary?: AllTimeComparisonSummary;
  playerName: string;
}

const TotalEffortInsights: React.FC<Props> = ({
  currentSummary,
  historicSummary,
  dataValue,
  timeValue,
  avgMax,
  metric,
  decimals,
  firstTwentyDaySummary,
  lastTwentyDaySummary,
  playerName,
}) => {
  const {t} = useTranslation();
  const {currentStatistic, historicStatistic} = useMemo(() => {
    return getStatisticValues(
      avgMax,
      dataValue.value,
      currentSummary,
      historicSummary,
    );
  }, [avgMax, currentSummary, dataValue, historicSummary]);
  const {currentComparedValue, historicComparedValue, comparedValuesText} =
    useMemo(() => {
      switch (dataValue.value) {
        case 'strideCount':
          return {
            currentComparedValue: currentSummary.totalStrides,
            historicComparedValue: historicSummary?.totalStrides,
            comparedValuesText: getComparativeText(
              timeValue,
              currentSummary.totalStrides,
              historicSummary?.totalStrides ?? null,
              dataValue.value,
              playerName,
              t,
            ),
          };
        case 'activeTime':
          return {
            currentComparedValue: currentSummary.totalTimeOnIce,
            historicComparedValue: historicSummary?.totalTimeOnIce,
            comparedValuesText: getComparativeText(
              timeValue,
              currentSummary.totalTimeOnIce,
              historicSummary?.totalTimeOnIce ?? null,
              dataValue.value,
              playerName,
              t,
            ),
          };
        case 'heartRate':
          return {
            currentComparedValue: currentSummary.timeAboveTarget,
            historicComparedValue: historicSummary?.timeAboveTarget,
            comparedValuesText: getComparativeText(
              timeValue,
              currentSummary.timeAboveTarget,
              historicSummary?.timeAboveTarget ?? null,
              dataValue.value,
              playerName,
              t,
            ),
          };
        case 'speed':
        case 'accel':
        case 'strint':
        case 'balance':
          return {
            comparedValuesText: getComparativeText(
              timeValue,
              currentStatistic,
              historicStatistic,
              dataValue.value,
              playerName,
              t,
            ),
          };
        default:
          return {
            comparedValuesText: '',
          };
      }
    }, [
      currentStatistic,
      currentSummary.timeAboveTarget,
      currentSummary.totalStrides,
      currentSummary.totalTimeOnIce,
      dataValue.value,
      historicStatistic,
      historicSummary?.timeAboveTarget,
      historicSummary?.totalStrides,
      historicSummary?.totalTimeOnIce,
      playerName,
      t,
      timeValue,
    ]);

  const {currentAllTimeStat, prevAllTimeStat, allTimeText} = useMemo(() => {
    const hasComparativeData =
      !!firstTwentyDaySummary && !!lastTwentyDaySummary;
    switch (dataValue.value) {
      case 'strideCount':
        return {
          currentAllTimeStat: currentSummary.totalStrides,
          prevAllTimeStat: null,
          allTimeText: t('totalEffort.youAllTimeSum', {
            name: playerName,
            verb: 'taken',
            value: currentSummary.totalStrides,
            metric: 'strides',
          }),
        };
      case 'activeTime':
        return {
          currentAllTimeStat: currentSummary.totalTimeOnIce,
          prevAllTimeStat: null,
          allTimeText: t('totalEffort.youAllTimeSum', {
            name: playerName,
            verb: 'skated for',
            value: Math.floor(currentSummary.totalTimeOnIce / 3600),
            metric: 'hours',
          }),
        };
      default:
        return hasComparativeData
          ? getAllTimeComparison(
              avgMax,
              lastTwentyDaySummary,
              firstTwentyDaySummary,
              dataValue.value,
              playerName,
              t,
            )
          : {};
    }
  }, [
    avgMax,
    currentSummary.totalStrides,
    currentSummary.totalTimeOnIce,
    dataValue.value,
    firstTwentyDaySummary,
    lastTwentyDaySummary,
    playerName,
    t,
  ]);

  if (timeValue !== TimeValue.allTime)
    return (
      <InsightCard
        icon={<BrainIcon />}
        title={`${dataValue.label} Insights`}
        intro={comparedValuesText}
        currentComparedValue={currentComparedValue ?? currentStatistic ?? 0}
        historicComparedValue={historicComparedValue ?? historicStatistic}
        statLabel={metric}
        decimals={decimals}
        thisPeriodLabel={t('general.thisValue', {value: timeValue})}
        lastPeriodLabel={t('general.lastValue', {value: timeValue})}
        convertMinutes={
          dataValue.value === 'activeTime' || dataValue.value === 'heartRate'
        }
      />
    );
  if (!currentAllTimeStat) {
    return null;
  }
  return (
    <InsightCard
      icon={<BrainIcon />}
      title={`${dataValue.label} Insights`}
      intro={allTimeText}
      currentComparedValue={currentAllTimeStat ?? 0}
      historicComparedValue={prevAllTimeStat}
      statLabel={metric}
      decimals={decimals}
      thisPeriodLabel={t('totalEffort.now')}
      lastPeriodLabel={t('totalEffort.initialSessions')}
      convertMinutes={
        dataValue.value === 'activeTime' || dataValue.value === 'heartRate'
      }
    />
  );
};

export default TotalEffortInsights;
