import {Container} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getPlayerSessionDetailAsync,
  updatePlayerSessionAsync,
} from '../../store/playerSessionSlice';
import {
  createSessionVideoSegmentAsync,
  deleteSessionVideoSegmentAsync,
  processSessionVideoAsync,
} from '../../store/sessionVideoSlice';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {createSessionVideoSegmentComplete} from '../../api/sessionVideoAPI';
import {Helmet} from 'react-helmet';
import VideoManagement from '../shared/VideoManagement';
import {CurrentMembershipLevels, VideoTypes} from '../../enums';
import MembershipLocked from '../shared/MembershipLocked';
import PageTitle from '../shared/PageTitle';
import BackButton from '../shared/BackButton';
import {useTranslation} from 'react-i18next';
import ProfileIdentity from '../shared/ProfileIdentity';

interface Props {}

const PlayerSessionVideoDetail: React.FC<Props> = props => {
  const {t} = useTranslation();
  const currentState: any = useAppSelector(state => ({
    profile: state.profile,
    playerSession: state.playerSession,
    team: state.team,
    family: state.family,
  }));
  const dispatch = useAppDispatch();
  const {playerSessionId, videoId, teamId, profileId} = useParams();
  const playerSessionIdInt = parseInt(playerSessionId ?? '');
  const videoIdInt = parseInt(videoId ?? '');
  const teamIdInt = parseInt(teamId ?? '');
  const profileIdInt = parseInt(profileId ?? '');

  const [sessionVideoSegments, setSessionVideoSegments] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getPlayerSessionDetailAsync(playerSessionIdInt));
  }, [dispatch, playerSessionIdInt]);

  useEffect(() => {
    const sessionVideos: any[] = (
      currentState.playerSession?.playerSession?.sessionVideos || []
    ).filter((video: any) => video.id === videoIdInt);
    setSessionVideoSegments(
      sessionVideos?.length === 1 ? sessionVideos[0].sessionVideoSegments : [],
    );
  }, [currentState.playerSession?.playerSession?.sessionVideos, videoIdInt]);

  const title = t('navigation.pageTitle', {
    pageName: t('pages.playerSessionVideo'),
  });

  if (!currentState.playerSession?.playerSession) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>Player Session Video</PageTitle>
        <BackButton
          navigationUrl={
            teamIdInt && profileIdInt
              ? `/teams/${teamIdInt}/players/${profileIdInt}/playersessions/${playerSessionIdInt}`
              : profileIdInt
                ? `/family/children/${profileIdInt}/playersessions/${playerSessionIdInt}`
                : `/playersessions/${playerSessionIdInt}`
          }>
          {t('navigation.back', {pageName: t('pages.playerSessionDetail')})}
        </BackButton>
        {currentState?.profile?.profile?.id !==
          currentState.playerSession?.playerSession?.player?.profile?.id && (
          <ProfileIdentity
            firstName={
              currentState.playerSession?.playerSession?.player?.profile
                ?.firstName
            }
            lastName={
              currentState.playerSession?.playerSession?.player?.profile
                ?.lastName
            }
            position={
              currentState.playerSession?.playerSession?.player?.profile
                ?.position
            }
            profilePictureUrl={
              currentState.playerSession?.playerSession?.player?.profile
                ?.profilePictureFileKey
            }
          />
        )}
        {currentState?.playerSession?.playerSession?.membershipLevel ===
        CurrentMembershipLevels.pro ? (
          <>
            <VideoManagement
              timeZone={currentState.playerSession?.playerSession?.timeZone}
              onTimeZoneChange={(timeZone: string) => {
                dispatch(
                  updatePlayerSessionAsync({
                    id: playerSessionIdInt,
                    params: {
                      timeZone,
                    },
                  }),
                );
              }}
              isProcessing={
                !!currentState.playerSession?.playerSession
                  ?.videoProcessingStartedAt
              }
              sessionVideoSegments={sessionVideoSegments}
              sessionEvents={
                currentState?.playerSession?.playerSession
                  ?.playerSessionSessionEvents ?? []
              }
              refresh={async () =>
                dispatch(getPlayerSessionDetailAsync(playerSessionIdInt))
              }
              createSegment={async (params: {
                fileName?: string;
                parts?: number;
                veoUrl?: string;
                pixellotUrl?: string;
                userSelectedStartTime?: Date;
                userSelectedType?: VideoTypes;
              }) =>
                dispatch(
                  createSessionVideoSegmentAsync({
                    sessionVideoId: videoIdInt,
                    fileName: params.fileName,
                    parts: params.parts,
                    veoUrl: params.veoUrl,
                    pixellotUrl: params.pixellotUrl,
                    userSelectedStartTime: params.userSelectedStartTime,
                    userSelectedType: params.userSelectedType,
                  }),
                )
              }
              createSegmentComplete={async (params: {
                uploadId: string;
                key: string;
                parts: object[];
              }) =>
                createSessionVideoSegmentComplete({
                  sessionVideoId: videoIdInt,
                  uploadId: params.uploadId,
                  key: params.key,
                  parts: params.parts,
                })
              }
              deleteSegment={async (id: number) =>
                dispatch(
                  deleteSessionVideoSegmentAsync({
                    sessionVideoSegmentId: id,
                  }),
                )
              }
              processVideo={async () =>
                dispatch(
                  processSessionVideoAsync({
                    sessionVideoId: videoIdInt,
                  }),
                )
              }
            />
          </>
        ) : (
          <MembershipLocked minMembershipLevel={CurrentMembershipLevels.pro} />
        )}
      </Container>
    </>
  );
};

export default PlayerSessionVideoDetail;
