import {Box, Typography} from '@mui/material';
import React from 'react';
import {COLOR_GRAY_MEDIUM} from '../../../styles';

interface Props {
  legendLabel?: string;
  statValue: string;
  statLabel: string;
}

const ChartLegendItem: React.FC<Props> = ({
  legendLabel,
  statValue,
  statLabel,
}) => (
  <Box>
    {legendLabel && (
      <Typography
        sx={{
          color: '#FFF',
          textTransform: 'uppercase',
          fontWeight: 400,
          fontSize: '1.2rem',
        }}>
        {legendLabel}
      </Typography>
    )}
    <Box sx={{display: 'flex', flexDirection: 'row'}}>
      <Typography
        sx={{
          color: '#FFF',
          fontWeight: 700,
          fontSize: '1.5rem',
        }}>
        {statValue}
      </Typography>
      <Typography
        sx={{
          color: COLOR_GRAY_MEDIUM,
          fontWeight: 700,
          fontSize: '1.2rem',
          alignContent: 'flex-end',
          marginLeft: 1,
        }}>
        {statLabel}
      </Typography>
    </Box>
  </Box>
);

export default ChartLegendItem;
