import {Box, styled, Typography} from '@mui/material';
import React, {useMemo} from 'react';
import {TimeValue} from '../../../enums';
import {DataValue} from './PlayerPlotChart';
import {GRAPH_YELLOW, ORANGE_COLOR} from '../../../styles';
import {useTranslation} from 'react-i18next';
import {getStatisticValues} from '../../../services/playerPlotChartService';
import {SummaryInRange} from '../../../interfaces';
import ChartLegendItem from './ChartLegendItem';

const ChartExampleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  marginRight: 20,
}));

const ChartLineExample = styled(Box)(() => ({
  borderWidth: 2,
  borderTopStyle: 'solid',
  minWidth: 50,
  marginLeft: 5,
  alignSelf: 'center',
}));

interface Props {
  dataValue: DataValue;
  timeValue: TimeValue;
  usesAvgMaxToggle: boolean;
  currentSummary: SummaryInRange;
  historicSummary: SummaryInRange | null;
  avgMax: 'avgData' | 'maxData';
  decimals: number;
  metric: string;
}

const TotalEffortLegend: React.FC<Props> = ({
  dataValue,
  timeValue,
  usesAvgMaxToggle,
  currentSummary,
  historicSummary,
  avgMax,
  decimals,
  metric,
}) => {
  const {t} = useTranslation();
  const {currentStatistic, historicStatistic} = useMemo(() => {
    return getStatisticValues(
      avgMax,
      dataValue.value,
      currentSummary,
      historicSummary,
    );
  }, [avgMax, currentSummary, dataValue.value, historicSummary]);
  return (
    <>
      {dataValue.value !== 'heartRate' && (
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginBottom: 2,
            }}>
            <Box>
              <ChartExampleContainer>
                <Typography sx={{textTransform: 'uppercase'}}>
                  {timeValue === TimeValue.allTime
                    ? t('general.allTime')
                    : t('general.thisValue', {value: timeValue})}
                  {usesAvgMaxToggle ? '' : ' (avg)'}
                </Typography>
                <ChartLineExample
                  sx={{
                    borderColor: ORANGE_COLOR,
                  }}
                />
              </ChartExampleContainer>
              {currentStatistic ? (
                <Box sx={{justifyContent: 'flex-start'}}>
                  <ChartLegendItem
                    statValue={currentStatistic.toFixed(decimals)}
                    statLabel={metric}
                  />
                </Box>
              ) : (
                <Box />
              )}
            </Box>
            {timeValue === TimeValue.allTime ? null : (
              <Box>
                <ChartExampleContainer>
                  <Typography sx={{textTransform: 'uppercase'}}>
                    {t('general.lastValue', {value: timeValue})}
                    {usesAvgMaxToggle ? '' : ' (avg)'}
                  </Typography>
                  <ChartLineExample
                    sx={{
                      borderColor: GRAPH_YELLOW,
                    }}
                  />
                </ChartExampleContainer>
                {historicStatistic ? (
                  <ChartLegendItem
                    statValue={historicStatistic.toFixed(decimals)}
                    statLabel={metric}
                  />
                ) : (
                  <Box />
                )}
              </Box>
            )}
            <Box>
              <ChartExampleContainer>
                <Typography sx={{textTransform: 'uppercase'}}>
                  {t('general.baseline')}
                </Typography>
                <ChartLineExample
                  sx={{
                    borderColor: 'rgba(255,255,255,0.8)',
                  }}
                />
              </ChartExampleContainer>
              <Box />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TotalEffortLegend;
