import {Box, styled, Typography} from '@mui/material';
import React from 'react';
import {
  CARD_BORDER_RADIUS,
  COLOR_GRAY_MEDIUM,
  GRAPH_YELLOW,
  ORANGE_COLOR,
} from '../../../styles';

const StatWrapper = styled(Typography)(() => ({
  color: '#FFF',
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '1.5rem',
}));
const StatLabel = styled(Typography)(() => ({
  color: COLOR_GRAY_MEDIUM,
  fontWeight: 700,
  fontSize: '1.2rem',
  alignContent: 'flex-end',
  marginLeft: 1,
}));
const BarContainer = styled(Box)(() => ({
  height: 20,
  marginTop: 1,
  marginBottom: 2,
  justifyContent: 'center',
  position: 'relative',
}));
const BarGraph = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  backgroundColor: ORANGE_COLOR,
  borderRadius: 6,
  height: '100%',
}));
const PeriodLabel = styled(Typography)(() => ({
  color: '#FFFFFF',
  textTransform: 'uppercase',
  marginLeft: 4,
  fontWeight: 700,
  position: 'relative',
}));

interface Props {
  icon: React.ReactNode;
  title: string;
  intro: string;
  currentComparedValue: number;
  historicComparedValue?: number | null;
  statLabel: string;
  decimals: number;
  thisPeriodLabel: string;
  lastPeriodLabel: string;
  convertMinutes: boolean;
}

const InsightCard: React.FC<Props> = ({
  icon,
  title,
  intro,
  currentComparedValue,
  historicComparedValue,
  statLabel,
  decimals,
  thisPeriodLabel,
  lastPeriodLabel,
  convertMinutes,
}) => (
  <Box
    sx={{
      padding: 4,
      borderRadius: CARD_BORDER_RADIUS,
      backgroundColor: 'rgba(116,116,128,0.18)',
      borderColor: '#808080',
      borderWidth: 0.4,
    }}>
    <Box sx={{display: 'flex'}}>
      <Box
        sx={{
          marginRight: 2,
          alignSelf: 'center',
        }}>
        {icon}
      </Box>
      <Typography
        sx={{
          fontSize: 18,
          color: '#fff',
          textTransform: 'uppercase',
        }}>
        {title}
      </Typography>
    </Box>
    <Box>
      <Typography>{intro}</Typography>
    </Box>
    {convertMinutes ? (
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <StatWrapper>{Math.floor(currentComparedValue / 3600)}</StatWrapper>
        <StatLabel>hr</StatLabel>
        <StatWrapper sx={{marginLeft: 1}}>
          {Math.ceil((currentComparedValue / 60) % 60)}
        </StatWrapper>
        <StatLabel>min</StatLabel>
      </Box>
    ) : (
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <StatWrapper>{currentComparedValue.toFixed(decimals)}</StatWrapper>
        <StatLabel>{statLabel}</StatLabel>
      </Box>
    )}
    <BarContainer>
      <BarGraph
        sx={{
          width:
            historicComparedValue === undefined ||
            historicComparedValue === null ||
            currentComparedValue > historicComparedValue
              ? '100%'
              : `${
                  (historicComparedValue > 0
                    ? currentComparedValue / historicComparedValue
                    : 0) * 100
                }%`,
        }}
      />
      <PeriodLabel>{thisPeriodLabel}</PeriodLabel>
    </BarContainer>
    {historicComparedValue !== null && historicComparedValue !== undefined && (
      <>
        {convertMinutes ? (
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <StatWrapper>
              {Math.floor(historicComparedValue / 3600)}
            </StatWrapper>
            <StatLabel>hr</StatLabel>
            <StatWrapper sx={{marginLeft: 1}}>
              {Math.ceil((historicComparedValue / 60) % 60)}
            </StatWrapper>
            <StatLabel>min</StatLabel>
          </Box>
        ) : (
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <StatWrapper>{historicComparedValue.toFixed(decimals)}</StatWrapper>
            <StatLabel>{statLabel}</StatLabel>
          </Box>
        )}
        <BarContainer>
          <BarGraph
            sx={{
              backgroundColor: GRAPH_YELLOW,
              width:
                historicComparedValue > currentComparedValue
                  ? '100%'
                  : `${
                      (currentComparedValue > 0
                        ? historicComparedValue / currentComparedValue
                        : 0) * 100
                    }%`,
            }}
          />
          <PeriodLabel>{lastPeriodLabel}</PeriodLabel>
        </BarContainer>
      </>
    )}
  </Box>
);

export default InsightCard;
