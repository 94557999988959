import {DateTime} from 'luxon';
import i18n from '../i18n/i18n';
import {VideoTypes} from '../enums';
import livebarnLogo from '../images/livebarn_logo.png';
import veoLogo from '../images/veo_logo.png';
import pixellotLogo from '../images/pixellot_logo.png';
import scorecamLogo from '../images/scorecam_logo.png';
import appleLogo from '../images/apple_logo.png';
import androidLogo from '../images/android_logo.png';
import floHockeyLogo from '../images/flo_hockey_logo.png';
import mnHockeyTvLogo from '../images/mn_hockey_tv_logo.png';
import blackbearLogo from '../images/blackbeartv-logo.png';

const livebarnRegex = /.+(\d{4}-\d{2}-\d{2}T\d{6})(\s\(\d+\))?\.mp4/i;
const veoMatchIdentifierRegex = /^https:\/\/app\.veo\.co\/matches\/(.+)$/i;
const pixellotMatchIdentifierRegex =
  /^https:\/\/you\.pixellot\.link\/([a-zA-Z0-9]+)$/i;
const iosRegex = /IMG_\d{4}(\s\(\d+\))?\.MOV/i;
const goProRegex = /GH\d{6}\.MP4/i;
const scorecamRegex =
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?\.mp4$/i;
const mp4Regex = /.+\.mp4/i;
const movRegex = /.+\.mov/i;
// android uses different formats for each device manufacturer so we just need an
// .mp4 catchall
const androidRegex = /.+\.mp4/i;

const videoTypes: {
  type: VideoTypes;
  name: string;
  image?: string;
  width?: string;
}[] = [
  {
    type: VideoTypes.livebarn,
    name: 'LiveBarn',
    image: livebarnLogo,
    width: '125%',
  },
  {
    type: VideoTypes.blackbear,
    name: 'Black Bear TV',
    image: blackbearLogo,
    width: '125%',
  },
  {
    type: VideoTypes.veo,
    name: 'Veo',
    image: veoLogo,
  },
  {
    type: VideoTypes.ios,
    name: 'iOS',
    image: appleLogo,
    width: '50%',
  },
  {
    type: VideoTypes.android,
    name: 'Android',
    image: androidLogo,
    width: '50%',
  },
  {
    type: VideoTypes.pixellot,
    name: 'Pixellot',
    image: pixellotLogo,
  },
  {
    type: VideoTypes.mnhockeytv,
    name: 'MN Hockey TV',
    image: mnHockeyTvLogo,
    width: '60%',
  },
  {
    type: VideoTypes.flohockey,
    name: 'FloHockey',
    image: floHockeyLogo,
  },
  {
    type: VideoTypes.scorecam,
    name: 'ScoreCam',
    image: scorecamLogo,
    width: '60%',
  },
  {
    type: VideoTypes.mp4,
    name: 'Other',
  },
];

const getFilenamesError = (filenames: string[]): string | null => {
  // make sure each file is valid type
  let hasLivebarnFiles = false;
  let hasVeoFiles = false;
  let hasPixellotFiles = false;
  let hasIosFiles = false;
  let hasScorecamFiles = false;
  let hasMp4Files = false;
  let hasMovFiles = false;
  let hasGoProFiles = false;
  for (let i = 0; i < filenames.length; i++) {
    const livebarnRes = livebarnRegex.exec(filenames[i]);
    const veoRes = veoMatchIdentifierRegex.exec(filenames[i]);
    const pixellotRes = pixellotMatchIdentifierRegex.exec(filenames[i]);
    const iosRes = iosRegex.exec(filenames[i]);
    const androidRes = androidRegex.exec(filenames[i]);
    const scorecamRes = scorecamRegex.exec(filenames[i]);
    const mp4Res = mp4Regex.exec(filenames[i]);
    const movRes = movRegex.exec(filenames[i]);
    const goProRes = goProRegex.exec(filenames[i]);
    if (
      !livebarnRes &&
      !veoRes &&
      !pixellotRes &&
      !iosRes &&
      !androidRes &&
      !scorecamRes &&
      !mp4Res &&
      !movRes
    ) {
      return i18n.t('video.filenamesUnknownTypeError', {
        filename: filenames[i],
      });
    }

    // if its livebarn then add timestamp to array
    if (livebarnRes) {
      hasLivebarnFiles = true;
    }

    if (veoRes) {
      hasVeoFiles = true;
    }

    if (pixellotRes) {
      hasPixellotFiles = true;
    }

    if (iosRes) {
      hasIosFiles = true;
    }

    if (scorecamRes) {
      hasScorecamFiles = true;
    }

    // we only treat the file as mp4 if it doesn't match other filename types that end in .mp4
    if (mp4Res && !livebarnRes && !scorecamRes) {
      hasMp4Files = true;
    }

    if (movRes && !iosRes) {
      hasMovFiles = true;
    }

    if (goProRes) {
      hasGoProFiles = true;
    }

    if (!livebarnRes && !veoRes && !pixellotRes && !iosRes && !goProRes) {
      // FIXME, we can't reliably detect android files due to the variations in filenames
      // eventually we'll uncomment this
      // if (androidRes) {
      //   hasAndroidFiles = true;
      // }
    }
  }

  let fileTypeCount = 0;
  if (hasLivebarnFiles) {
    fileTypeCount++;
  }
  if (hasVeoFiles) {
    fileTypeCount++;
  }
  if (hasPixellotFiles) {
    fileTypeCount++;
  }
  if (hasIosFiles) {
    fileTypeCount++;
  }
  if (hasGoProFiles) {
    fileTypeCount++;
  }
  if (hasScorecamFiles) {
    fileTypeCount++;
  }
  if (hasMp4Files) {
    fileTypeCount++;
  }
  if (hasMovFiles) {
    fileTypeCount++;
  }

  console.log(hasMovFiles, hasIosFiles);

  // if using multiple types throw an error
  if (fileTypeCount > 1) {
    return i18n.t('video.filenamesMultipleTypesError');
  }

  return null;
};

const getLivebarnMissingSegmentWarning = (
  filenames: string[],
): string | null => {
  const timestampStrings = [];

  for (let i = 0; i < filenames.length; i++) {
    const livebarnRes = livebarnRegex.exec(filenames[i]);

    // if its livebarn then add timestamp to array
    if (livebarnRes) {
      timestampStrings.push(livebarnRes[1]);
    }
  }

  const timestamps = timestampStrings.sort().map(timestamp => {
    return DateTime.fromISO(timestamp);
  });

  // skip the first file
  for (let i = 1; i < timestamps.length; i++) {
    const diffMinutes = timestamps[i].diff(
      timestamps[i - 1],
      'minutes',
    ).minutes;
    // make sure the gap isn't shorter than .1 minute which means that its probably the same filename
    // and make sure the gap isn't longer than 32 minutes indicating a segment is missing
    if (diffMinutes < 0.1 || diffMinutes > 32) {
      return i18n.t('video.livebarnMissingSegmentWarning');
    }
  }

  return null;
};

const getFilenamesMessage = (filenames: string[]): string | null => {
  let hasLivebarnFiles = false;

  for (let i = 0; i < filenames.length; i++) {
    const livebarnRes = livebarnRegex.exec(filenames[i]);

    if (livebarnRes) {
      hasLivebarnFiles = true;
    }
  }

  if (hasLivebarnFiles) {
    // show a default message indicating that livebarn files can be shorter than 30 mins, so make sure to download all of them
    return i18n.t('video.livebarn30minMessage');
  }

  return null;
};

export {
  videoTypes,
  getFilenamesError,
  getFilenamesMessage,
  getLivebarnMissingSegmentWarning,
  livebarnRegex,
  veoMatchIdentifierRegex,
  pixellotMatchIdentifierRegex,
  iosRegex,
  androidRegex,
  scorecamRegex,
  mp4Regex,
  goProRegex,
};
