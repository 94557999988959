import {
  createSessionVideo,
  createSessionVideoSegment,
  deleteSessionVideoSegment,
  processSessionVideo,
} from '../api/sessionVideoAPI';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {uploadToS3} from '../api/s3API';
import {FileUploadStatus, SessionVideoSegment} from '../interfaces';
import {VideoTypes} from '../enums';

export interface SessionVideoState {
  status: 'idle' | 'loading' | 'failed';
  error: any;
  uploadStatus: 'idle' | 'loading' | 'failed';
  uploadError: any;
}

const initialState: SessionVideoState = {
  status: 'idle',
  error: null,
  uploadStatus: 'idle',
  uploadError: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
const createSessionVideoAsync = createAsyncThunk(
  'sessionVideo/createSessionVideo',
  async (
    params: {
      playerSessionId?: number;
      teamSessionId?: number;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: any = await createSessionVideo(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

interface CreateSegmentResponsePayload {
  sessionVideoSegment: SessionVideoSegment;
  multiPartSignedUrlList: {
    PartNumber: number;
    signedUrl: string;
  }[];
  uploadId: string;
  key: string;
}

const createSessionVideoSegmentAsync = createAsyncThunk(
  'sessionVideo/createSessionVideoSegment',
  async (
    params: {
      sessionVideoId: number;
      fileName?: string;
      parts?: number;
      veoUrl?: string;
      pixellotUrl?: string;
      userSelectedStartTime?: Date;
      userSelectedType?: VideoTypes;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: {
        data: {
          payload: SessionVideoSegment;
        };
      } = await createSessionVideoSegment(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const deleteSessionVideoSegmentAsync = createAsyncThunk(
  'sessionVideo/deleteSessionVideoSegment',
  async (
    params: {
      sessionVideoSegmentId: number;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: any = await deleteSessionVideoSegment(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const uploadSessionVideoSegmentAsync = createAsyncThunk(
  'sessionVideo/uploadSessionVideoSegment',
  async (
    params: {
      preAuthUrl: string;
      file: File;
      onUploadProgress: (progressParams: FileUploadStatus) => void;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: any = await uploadToS3(
        params.preAuthUrl,
        params.file,
        params.onUploadProgress,
      );
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

const processSessionVideoAsync = createAsyncThunk(
  'sessionVideo/processSessionVideo',
  async (
    params: {
      sessionVideoId: number;
    },
    {rejectWithValue},
  ) => {
    try {
      const response: any = await processSessionVideo(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (err: any) {
      return rejectWithValue({
        name: err.name,
        message: err.message,
      });
    }
  },
);

export const sessionVideoSlice = createSlice({
  name: 'sessionVideo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(createSessionVideoAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(createSessionVideoAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createSessionVideoAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createSessionVideoSegmentAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(createSessionVideoSegmentAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(createSessionVideoSegmentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteSessionVideoSegmentAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(deleteSessionVideoSegmentAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(deleteSessionVideoSegmentAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(uploadSessionVideoSegmentAsync.pending, state => {
        state.uploadStatus = 'loading';
      })
      .addCase(uploadSessionVideoSegmentAsync.fulfilled, (state, action) => {
        state.uploadStatus = 'idle';
        state.uploadError = null;
      })
      .addCase(uploadSessionVideoSegmentAsync.rejected, (state, action) => {
        state.uploadStatus = 'failed';
        state.uploadError = action.payload;
      })
      .addCase(processSessionVideoAsync.pending, state => {
        state.status = 'loading';
      })
      .addCase(processSessionVideoAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.error = null;
      })
      .addCase(processSessionVideoAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default sessionVideoSlice.reducer;

export {
  createSessionVideoAsync,
  createSessionVideoSegmentAsync,
  deleteSessionVideoSegmentAsync,
  uploadSessionVideoSegmentAsync,
  processSessionVideoAsync,
};

// interfaces
export type {CreateSegmentResponsePayload};
