import React, {useMemo} from 'react';
import {Box, Typography} from '@mui/material';
import styles, {CARD_BORDER_RADIUS, COLOR_GRAY_MEDIUM} from '../../../styles';
import {SizeMe} from 'react-sizeme';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryStack,
} from 'victory';
import {
  getWeekTickFormatFunction,
  xAxisStyle,
  xAxisWithTicksStyle,
  yAxisStyle,
} from '../../../services/graphService';
import {TimeValue} from '../../../enums';
import {useTranslation} from 'react-i18next';
import ChartLegendItem from './ChartLegendItem';

const componentStyles = {
  horizontalBar: {
    height: 20,
  },
  statLabel: {
    color: COLOR_GRAY_MEDIUM,
    fontSize: 18,
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  stat: {
    color: '#FFFFFF',
    // fontFamily: ORBITRON_BOLD,
    fontSize: 20,
  },
  card: {
    padding: 4,
    borderRadius: CARD_BORDER_RADIUS,
    backgroundColor: 'rgba(116,116,128,0.18)',
    marginBottom: 10,
    borderColor: '#808080',
    borderWidth: 0.4,
  },
};

interface RatioBarProps {
  color: string;
  title: string;
  time: number;
  timeTotal: number;
}

const RatioBar: React.FC<RatioBarProps> = ({color, title, time, timeTotal}) => (
  <Box
    sx={{
      ...styles.flexDirectionRow,
      ...styles.marginTop,
    }}>
    <Box style={styles.flexGrow}>
      <Typography
        sx={[componentStyles.statLabel, {textTransform: 'uppercase'}]}>
        {title}
      </Typography>
      <Box>
        <Box
          sx={{
            ...componentStyles.horizontalBar,
            backgroundColor: color,
            width: `${timeTotal > 0 ? (time / timeTotal) * 100 : 0}%`,
          }}
        />
      </Box>
    </Box>
    <Box sx={[componentStyles.alignItemsEnd, {width: 60}]}>
      <Typography sx={componentStyles.stat}>
        {Math.ceil(timeTotal > 0 ? (time / timeTotal) * 100 : 0)}%
      </Typography>
      <Typography sx={componentStyles.statLabel}>
        {Math.ceil(time / 60)} m
      </Typography>
    </Box>
  </Box>
);

const hrColors = ['#363C3F', '#FFC130', '#FF8B1C', '#F25F14', '#D72F2F'];
const hrLabels = ['Recovery', 'Warm up', 'Fat Burn', 'Cardio', 'Peak'];

interface Props {
  hrZoneTime: number[][];
  timeRange: TimeValue;
  hrMin?: number;
  hrMax?: number;
  hrAvg?: number;
}

const TotalEffortHeartRateChart: React.FC<Props> = ({
  hrZoneTime,
  timeRange,
  hrMin,
  hrMax,
  hrAvg,
}) => {
  const {t} = useTranslation();
  const hrZoneTimeTotals = useMemo(() => {
    return hrZoneTime.reduce(
      (acc: number[], value: number[]) => {
        acc[0] = acc[0] + value[0];
        acc[1] = acc[1] + value[1];
        acc[2] = acc[2] + value[2];
        acc[3] = acc[3] + value[3];
        acc[4] = acc[4] + value[4];
        return acc;
      },
      [0, 0, 0, 0, 0],
    );
  }, [hrZoneTime]);
  const hrZoneTimeTotal = useMemo(() => {
    return hrZoneTimeTotals.reduce((a: number, b: number) => a + b, 0);
  }, [hrZoneTimeTotals]);
  return (
    <Box>
      <SizeMe>
        {({size}) => (
          <VictoryChart
            width={size.width ? size.width - 20 : 1200}
            padding={{left: 30, right: 60, top: 50, bottom: 50}}>
            <VictoryStack colorScale={hrColors}>
              {[0, 1, 2, 3, 4].map(hrZoneIndex => (
                <VictoryBar
                  key={hrZoneIndex}
                  data={hrZoneTime.map(day => day[hrZoneIndex] / 60)}
                />
              ))}
            </VictoryStack>
            <VictoryAxis style={xAxisStyle} />
            <VictoryAxis
              dependentAxis
              orientation="right"
              style={yAxisStyle}
              tickFormat={value =>
                value.toFixed(0) !== '0' ? `${value.toFixed(0)} m` : ''
              }
            />
            {timeRange === 'week' ? (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(7).keys()]}
                tickFormat={getWeekTickFormatFunction(t, false)}
              />
            ) : (
              <VictoryAxis
                style={xAxisWithTicksStyle}
                tickValues={[...Array(hrZoneTime.length).keys()]}
                tickFormat={tick =>
                  tick === 0
                    ? t('general.daysAgo', {
                        numberOfDays: hrZoneTime.length,
                      })
                    : tick === hrZoneTime.length - 1
                      ? t('general.today')
                      : ''
                }
                tickLabelComponent={
                  <VictoryLabel
                    textAnchor={data => (data.index === 0 ? 'start' : 'end')}
                  />
                }
              />
            )}
          </VictoryChart>
        )}
      </SizeMe>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        {!!hrMin && !!hrMax && (
          <Box sx={{margin: 2}}>
            <ChartLegendItem
              legendLabel="Range"
              statValue={`${hrMin}-${hrMax}`}
              statLabel="bpm"
            />
          </Box>
        )}
        {!!hrAvg && (
          <Box sx={{margin: 2}}>
            <ChartLegendItem
              legendLabel="AVG HR"
              statValue={hrAvg.toString()}
              statLabel="bpm"
            />
          </Box>
        )}
      </Box>
      <Box sx={componentStyles.card}>
        <Typography variant="h4" sx={{textTransform: 'uppercase'}}>
          {t('general.ratio')}
        </Typography>
        {[4, 3, 2, 1, 0].map(hrZoneIndex => (
          <RatioBar
            key={hrZoneIndex}
            title={hrLabels[hrZoneIndex]}
            color={hrColors[hrZoneIndex]}
            time={hrZoneTimeTotals[hrZoneIndex]}
            timeTotal={hrZoneTimeTotal}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TotalEffortHeartRateChart;
