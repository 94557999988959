import {Container} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../store/hooks';
import {
  getTeamSessionDetailAsync,
  updateTeamSessionAsync,
} from '../../store/teamSessionSlice';
import {
  createSessionVideoSegmentAsync,
  deleteSessionVideoSegmentAsync,
  processSessionVideoAsync,
} from '../../store/sessionVideoSlice';
import FullScreenLoadingSpinner from '../shared/FullScreenLoading';
import {createSessionVideoSegmentComplete} from '../../api/sessionVideoAPI';
import {Helmet} from 'react-helmet';
import VideoManagement from '../shared/VideoManagement';
import PageTitle from '../shared/PageTitle';
import BackButton from '../shared/BackButton';
import {useTranslation} from 'react-i18next';
import {VideoTypes} from '../../enums';

interface Props {}

const TeamSessionVideoDetail: React.FC<Props> = props => {
  const {t} = useTranslation();
  const currentState = useAppSelector(state => ({
    teamSession: state.teamSession,
    family: state.family,
  }));
  const dispatch = useAppDispatch();
  const {teamId, teamSessionId, videoId} = useParams();
  const teamSessionIdInt = parseInt(teamSessionId || '');
  const videoIdInt = parseInt(videoId || '');

  const [sessionVideoSegments, setSessionVideoSegments] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getTeamSessionDetailAsync(teamSessionIdInt));
  }, [dispatch, teamSessionIdInt]);

  useEffect(() => {
    const sessionVideos: any[] = (
      currentState.teamSession?.teamSession?.sessionVideos || []
    ).filter((video: any) => video.id === videoIdInt);
    setSessionVideoSegments(
      sessionVideos?.length === 1 ? sessionVideos[0].sessionVideoSegments : [],
    );
  }, [currentState.teamSession?.teamSession?.sessionVideos, videoIdInt]);

  const title = t('navigation.pageTitle', {
    pageName: t('pages.teamSessionVideo'),
  });

  if (!currentState.teamSession?.teamSession) {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <FullScreenLoadingSpinner />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <PageTitle>Team Session Video</PageTitle>
        <BackButton
          navigationUrl={`/teams/${teamId}/teamsessions/${teamSessionIdInt}`}>
          {t('navigation.back', {pageName: t('pages.teamSessionDetail')})}
        </BackButton>
        <VideoManagement
          timeZone={currentState.teamSession?.teamSession?.timeZone}
          onTimeZoneChange={(timeZone: string) => {
            dispatch(
              updateTeamSessionAsync({
                id: teamSessionIdInt,
                params: {
                  timeZone,
                },
              }),
            );
          }}
          isProcessing={
            (
              currentState.teamSession?.teamSession?.playerSessions ?? []
            ).filter(
              (playerSession: any) => !!playerSession.videoProcessingStartedAt,
            ).length > 0
          }
          sessionVideoSegments={sessionVideoSegments}
          sessionEvents={
            currentState?.teamSession?.teamSession?.teamSessionSessionEvents ??
            []
          }
          refresh={async () =>
            dispatch(getTeamSessionDetailAsync(teamSessionIdInt))
          }
          createSegment={async (params: {
            fileName?: string;
            parts?: number;
            veoUrl?: string;
            pixellotUrl?: string;
            userSelectedStartTime?: Date;
            userSelectedType?: VideoTypes;
          }) =>
            dispatch(
              createSessionVideoSegmentAsync({
                sessionVideoId: videoIdInt,
                fileName: params.fileName,
                parts: params.parts,
                veoUrl: params.veoUrl,
                pixellotUrl: params.pixellotUrl,
                userSelectedStartTime: params.userSelectedStartTime,
                userSelectedType: params.userSelectedType,
              }),
            )
          }
          createSegmentComplete={async (params: {
            uploadId: string;
            key: string;
            parts: object[];
          }) =>
            createSessionVideoSegmentComplete({
              sessionVideoId: videoIdInt,
              uploadId: params.uploadId,
              key: params.key,
              parts: params.parts,
            })
          }
          deleteSegment={async (id: number) =>
            dispatch(
              deleteSessionVideoSegmentAsync({
                sessionVideoSegmentId: id,
              }),
            )
          }
          processVideo={async () =>
            dispatch(
              processSessionVideoAsync({
                sessionVideoId: videoIdInt,
              }),
            )
          }
        />
      </Container>
    </>
  );
};

export default TeamSessionVideoDetail;
