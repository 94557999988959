import {Box, Button, Paper, Typography} from '@mui/material';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Team, TeamSession} from '../../interfaces';
import {compare} from '../../helpers';
import {createLostPlayerSession} from '../../api/playerSessionAPI';

interface Props {
  team: Team;
  teamSession: TeamSession;
  refresh: () => void;
}

const CreateLostTeamSessionPlayerSession: React.FC<Props> = ({
  team,
  teamSession,
  refresh,
}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);

  const handleOpen = useCallback(() => setOpen(!open), [open]);

  const availableMissingPlayers = useMemo(
    () =>
      (team?.players ?? [])
        .filter(
          p =>
            (teamSession?.playerSessions ?? [])
              .map(ps => ps.player?.id)
              .indexOf(p.id) === -1,
        )
        .sort((a, b) => compare(a.profile?.lastName, b.profile?.lastName)),
    [team, teamSession],
  );

  return (
    <Paper sx={{mb: 2, mx: 2, p: 4, flex: 0.5}}>
      <Typography variant="h4">
        {`${t('admin.adminOnly')}: ${t('admin.createLostPlayerSession')}`}
      </Typography>
      {open && (
        <Box>
          {availableMissingPlayers
            .filter(p => p.profile?.id)
            .map(p => (
              <Button
                key={p.profile?.id}
                variant="contained"
                disabled={updating}
                sx={{m: 1}}
                onClick={async () => {
                  setUpdating(true);
                  // create lost player session
                  await createLostPlayerSession({
                    profileId: p.profile!.id!,
                    teamSessionId: teamSession.id,
                  });
                  // reload team session
                  await refresh();
                  setUpdating(false);
                }}>
                {p.profile?.lastName}
              </Button>
            ))}
        </Box>
      )}
      <Button variant="outlined" onClick={handleOpen} sx={{mt: 2}}>
        {open ? t('general.cancel') : t('general.continue')}
      </Button>
    </Paper>
  );
};

export default React.memo(CreateLostTeamSessionPlayerSession);
