import React, {ReactNode} from 'react';
import {Container} from '@mui/material';

interface Props {
  children: ReactNode;
}

const PageContainer: React.FC<Props> = ({children}) => {
  return (
    <Container
      sx={{
        px: {
          xs: 1,
          md: 2,
        },
      }}>
      {children}
    </Container>
  );
};

export default PageContainer;
