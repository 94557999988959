import React from 'react';
import {TeamSession} from '../../interfaces';
import {Box, styled} from '@mui/material';
import TeamShiftUtilizationCell from './TeamShiftUtilizationCell';
import {getTime} from '../../services/dateService';
import {Metric, PlayerData} from './TeamShiftUtilization';
import {useWindowSize} from '../../hooks/useWindowSize';

const UtilizationRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
}));

const SectionColumn = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  height: '100%',
}));

enum Period {
  fullGame = 'fullGame',
  period1 = 'period1',
  period2 = 'period2',
  period3 = 'period3',
  overtime = 'overtime',
}

const getCellLabel = (metric: Metric) => {
  switch (metric) {
    case Metric.timeOnIce:
      return 'Time on Ice';
    case Metric.strideTime:
      return 'Stride Time';
    case Metric.shiftCount:
      return 'Shift Count';
    case Metric.energyRatio:
      return 'Energy Ratio';
    default:
      return '';
  }
};

const getCellValue = (player: PlayerData, period: Period, metric: Metric) => {
  switch (metric) {
    case Metric.timeOnIce:
      return getTime(player[`${period}TimeOnIce`]) ?? '';
    case Metric.strideTime:
      return getTime(player[`${period}StrideTime`]) ?? '';
    case Metric.shiftCount:
      return (player[`${period}ShiftCount`] ?? '').toString();
    case Metric.energyRatio:
      return `${(player[`${period}EnergyRatio`] * 100).toFixed(0)}%`;
    default:
      return '';
  }
};

const getEnergyRatioColor = (energyRatio: string, baselineValue: number) => {
  if (!baselineValue) {
    return 'transparent';
  }
  const formattedEnergyRatio = energyRatio.replace('%', '');
  const processedBaseline = (baselineValue * 100).toFixed(0);
  if (parseInt(formattedEnergyRatio) >= parseInt(processedBaseline)) {
    // GREEN
    return 'hsl(120,50%,50%)';
  }
  // RED
  return 'hsl(0,50%,50%)';
};

interface Props {
  player: PlayerData;
  fullGameTeamSessions: TeamSession[];
  period1TeamSessions: TeamSession[];
  period2TeamSessions: TeamSession[];
  period3TeamSessions: TeamSession[];
  overtimeTeamSessions: TeamSession[];
  selectedMetric:
    | 'timeOnIce'
    | 'strideTime'
    | 'shiftCount'
    | 'energyRatio'
    | 'fullView';
}

const TeamShiftUtilizationRow: React.FC<Props> = ({
  player,
  fullGameTeamSessions,
  period1TeamSessions,
  period2TeamSessions,
  period3TeamSessions,
  overtimeTeamSessions,
  selectedMetric,
}) => {
  const [width] = useWindowSize();

  return (
    <UtilizationRow>
      {selectedMetric === 'fullView' ? (
        <>
          {fullGameTeamSessions.length > 0 ? (
            <SectionColumn>
              {Object.keys(Metric).map((keyString: string) => {
                const columnMetric = Metric[keyString as keyof typeof Metric];
                const value = getCellValue(
                  player,
                  Period.fullGame,
                  columnMetric,
                );
                return (
                  <TeamShiftUtilizationCell
                    key={`fullGame-${keyString}`}
                    errorState={player.fullGameErrorState}
                    utilization={player.fullGameUtilization[columnMetric]}
                    label={getCellLabel(columnMetric)}
                    value={value}
                    shrinkText={width < 1200 || !!player.fullGameErrorState}
                    overrideColor={
                      columnMetric === Metric.energyRatio
                        ? getEnergyRatioColor(
                            value,
                            player.fullGameBaselineEnergyRatio,
                          )
                        : ''
                    }
                  />
                );
              })}
            </SectionColumn>
          ) : null}
          {period1TeamSessions.length > 0 ? (
            <SectionColumn>
              {Object.keys(Metric).map((keyString: string) => {
                const columnMetric = Metric[keyString as keyof typeof Metric];
                const value = getCellValue(
                  player,
                  Period.period1,
                  columnMetric,
                );
                return (
                  <TeamShiftUtilizationCell
                    key={`period1-${keyString}`}
                    errorState={player.period1ErrorState}
                    utilization={player.period1Utilization[columnMetric]}
                    label={getCellLabel(columnMetric)}
                    value={value}
                    shrinkText={width < 1200 || !!player.period1ErrorState}
                    overrideColor={
                      columnMetric === Metric.energyRatio
                        ? getEnergyRatioColor(
                            value,
                            player.period1BaselineEnergyRatio,
                          )
                        : ''
                    }
                  />
                );
              })}
            </SectionColumn>
          ) : null}
          {period2TeamSessions.length > 0 ? (
            <SectionColumn>
              {Object.keys(Metric).map((keyString: string) => {
                const columnMetric = Metric[keyString as keyof typeof Metric];
                const value = getCellValue(
                  player,
                  Period.period2,
                  columnMetric,
                );
                return (
                  <TeamShiftUtilizationCell
                    key={`period2-${keyString}`}
                    errorState={player.period2ErrorState}
                    utilization={player.period2Utilization[columnMetric]}
                    label={getCellLabel(columnMetric)}
                    value={value}
                    shrinkText={width < 1200 || !!player.period2ErrorState}
                    overrideColor={
                      columnMetric === Metric.energyRatio
                        ? getEnergyRatioColor(
                            value,
                            player.period2BaselineEnergyRatio,
                          )
                        : ''
                    }
                  />
                );
              })}
            </SectionColumn>
          ) : null}
          {period3TeamSessions.length > 0 ? (
            <SectionColumn>
              {Object.keys(Metric).map((keyString: string) => {
                const columnMetric = Metric[keyString as keyof typeof Metric];
                const value = getCellValue(
                  player,
                  Period.period3,
                  columnMetric,
                );
                return (
                  <TeamShiftUtilizationCell
                    key={`period3-${keyString}`}
                    errorState={player.period3ErrorState}
                    utilization={player.period3Utilization[columnMetric]}
                    label={getCellLabel(columnMetric)}
                    value={value}
                    shrinkText={width < 1200 || !!player.period3ErrorState}
                    overrideColor={
                      columnMetric === Metric.energyRatio
                        ? getEnergyRatioColor(
                            value,
                            player.period3BaselineEnergyRatio,
                          )
                        : ''
                    }
                  />
                );
              })}
            </SectionColumn>
          ) : null}
          {overtimeTeamSessions.length > 0 ? (
            <SectionColumn>
              {Object.keys(Metric).map((keyString: string) => {
                const columnMetric = Metric[keyString as keyof typeof Metric];
                const value = getCellValue(
                  player,
                  Period.overtime,
                  columnMetric,
                );
                return (
                  <TeamShiftUtilizationCell
                    key={`overtime-${keyString}`}
                    errorState={player.overtimeErrorState}
                    utilization={player.overtimeUtilization[columnMetric]}
                    label={getCellLabel(columnMetric)}
                    value={value}
                    shrinkText={width < 1200 || !!player.overtimeErrorState}
                    overrideColor={
                      columnMetric === Metric.energyRatio
                        ? getEnergyRatioColor(
                            value,
                            player.overtimeBaselineEnergyRatio,
                          )
                        : ''
                    }
                  />
                );
              })}
            </SectionColumn>
          ) : null}
        </>
      ) : (
        <>
          {fullGameTeamSessions.length > 0 ? (
            <TeamShiftUtilizationCell
              errorState={player.fullGameErrorState}
              utilization={player.fullGameUtilization[selectedMetric]}
              label={getCellLabel(selectedMetric as Metric)}
              value={getCellValue(
                player,
                Period.fullGame,
                selectedMetric as Metric,
              )}
              overrideColor={
                selectedMetric === Metric.energyRatio
                  ? getEnergyRatioColor(
                      getCellValue(
                        player,
                        Period.fullGame,
                        selectedMetric as Metric,
                      ),
                      player.fullGameBaselineEnergyRatio,
                    )
                  : ''
              }
            />
          ) : null}
          {period1TeamSessions.length > 0 ? (
            <TeamShiftUtilizationCell
              errorState={player.period1ErrorState}
              utilization={player.period1Utilization[selectedMetric]}
              label={getCellLabel(selectedMetric as Metric)}
              value={getCellValue(
                player,
                Period.period1,
                selectedMetric as Metric,
              )}
              overrideColor={
                selectedMetric === Metric.energyRatio
                  ? getEnergyRatioColor(
                      getCellValue(
                        player,
                        Period.period1,
                        selectedMetric as Metric,
                      ),
                      player.period1BaselineEnergyRatio,
                    )
                  : ''
              }
            />
          ) : null}
          {period2TeamSessions.length > 0 ? (
            <TeamShiftUtilizationCell
              errorState={player.period2ErrorState}
              utilization={player.period2Utilization[selectedMetric]}
              label={getCellLabel(selectedMetric as Metric)}
              value={getCellValue(
                player,
                Period.period2,
                selectedMetric as Metric,
              )}
              overrideColor={
                selectedMetric === Metric.energyRatio
                  ? getEnergyRatioColor(
                      getCellValue(
                        player,
                        Period.period2,
                        selectedMetric as Metric,
                      ),
                      player.period2BaselineEnergyRatio,
                    )
                  : ''
              }
            />
          ) : null}
          {period3TeamSessions.length > 0 ? (
            <TeamShiftUtilizationCell
              errorState={player.period3ErrorState}
              utilization={player.period3Utilization[selectedMetric]}
              label={getCellLabel(selectedMetric as Metric)}
              value={getCellValue(
                player,
                Period.period3,
                selectedMetric as Metric,
              )}
              overrideColor={
                selectedMetric === Metric.energyRatio
                  ? getEnergyRatioColor(
                      getCellValue(
                        player,
                        Period.period3,
                        selectedMetric as Metric,
                      ),
                      player.period3BaselineEnergyRatio,
                    )
                  : ''
              }
            />
          ) : null}
          {overtimeTeamSessions.length > 0 ? (
            <TeamShiftUtilizationCell
              errorState={player.overtimeErrorState}
              utilization={player.overtimeUtilization[selectedMetric]}
              label={getCellLabel(selectedMetric as Metric)}
              value={getCellValue(
                player,
                Period.overtime,
                selectedMetric as Metric,
              )}
              overrideColor={
                selectedMetric === Metric.energyRatio
                  ? getEnergyRatioColor(
                      getCellValue(
                        player,
                        Period.overtime,
                        selectedMetric as Metric,
                      ),
                      player.overtimeBaselineEnergyRatio,
                    )
                  : ''
              }
            />
          ) : null}
        </>
      )}
    </UtilizationRow>
  );
};

export default TeamShiftUtilizationRow;
